import { Edit } from '@mui/icons-material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { Box, Button, Grid, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@mui/material/Checkbox';
import Loading from '../../../Common/Loading'
import {
  fetchRiskOptions,
  getRiskrecods,
} from '../../../redux/reducer/riskSlice'
import { getAllMetaData } from '../../../services/riskManagementService'
import ReadmeFile from './ReadmeFile'
import RiskForm from './RiskForm'
import RiskTable from './Risktable'
import { updateRiskInitialData } from '../../../redux/reducer/projectHealthFilterSlice'
import { updateSelectedRisksData } from '../../../redux/reducer/riskSlice'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Popper, useMediaQuery } from '@mui/material'
import FilterComponent from '../ProjectHealth/Common/multiSelectCustomFilter'
import { useTheme } from '@emotion/react'


const flexStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    leaveTouchDelay={3000}
    enterTouchDelay={20}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 600,
    maxWidth: 160,
    borderRadius: 3,
  },
}))
const RiskManagement = () => {
  const dispatch = useDispatch()
  const { data: adminData } = useSelector((state) => state.admin)
  const { userDetails } = useSelector((state) => state.user)
  const [openForm, setOpenForm] = useState(false)
  const [openReadme, setOpenReadme] = useState(false)
  const [currentData, setCurrentData] = useState([])
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const [uniqueVertical, setUniqueVertical] = useState([])
  const [uniqueAccount, setUniqueAccount] = useState([])
  const [editForm, setEditForm] = useState(false)
  const [editRowIndex, setEditRowIndex] = useState(null)
  const [rows, setRows] = useState([])
  const [uploadOpen, setUploadOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined


  const { riskData, selectedRisksData } = useSelector((state) => state.risk)
  const { riskInitialData, filterData } = useSelector((state) => state.filter)

  const handleFormOpen = () => {
    setOpenForm(true)
    setSelectedRows([])
  }

  const handleClick = (event) => {
    setSelectedRows([])
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleFormClose = () => {
    setOpenForm(false)
    setEditForm(false)
    setEditRowIndex(null)
  }
  function onClose() {
    handleFormClose()
  }
  const getMetaData = () => {
    setOpenBackdrop(true)
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    getAllMetaData(data)
      .then((res) => {
        if (res.success) {
          setCurrentData(res.data.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
      .finally(() => {
        setOpenBackdrop(false)
      })
  }

  const onEdit = (index) => () => {
    setEditRowIndex(index)
    setEditForm(true)
    handleFormOpen()
    setSelectedRows([])
  }

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Risk Data')

    worksheet.getRow(1).values = [
      'Vertical-Account-Project-Workstream',
      '',
      '',
      '',
      '',
      'Risk Identification',
      '',
      '',
      '',
      'Risk Analysis',
      '',
      '',
      'Risk Response',
      '',
      '',
      '',
      '',
      '',
    ]
    worksheet.mergeCells('A1:E1')
    worksheet.mergeCells('F1:I1')
    worksheet.mergeCells('J1:L1')
    worksheet.mergeCells('M1:R1')

    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { name: 'Poppins', size: 10, bold: true }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ffc000' },
      }
      cell.border = {
        top: { style: 'thin', color: { argb: '262626' } },
        left: { style: 'thin', color: { argb: '262626' } },
        bottom: { style: 'thin', color: { argb: '262626' } },
        right: { style: 'thin', color: { argb: '262626' } },
      }
    })

    worksheet.getRow(2).values = [
      '#',
      'Vertical',
      'Account',
      'Project',
      'Workstream',
      'Category',
      'Risk Description',
      'Consequence',
      'Existing Controls/Measures',
      'Likelihood',
      'Impact',
      'Risk Level',
      'Risk Response',
      'Risk Response Details',
      'Risk Owner',
      'Status',
      'ETA',
      'Updated On',
    ]
    worksheet.getRow(2).eachCell((cell) => {
      cell.font = { name: 'Poppins', size: 9 }
      cell.alignment = { vertical: 'middle', horizontal: 'center' }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE699' },
      }
      cell.border = {
        top: { style: 'thin', color: { argb: '262626' } },
        left: { style: 'thin', color: { argb: '262626' } },
        bottom: { style: 'thin', color: { argb: '262626' } },
        right: { style: 'thin', color: { argb: '262626' } },
      }
    })
    worksheet.columns = [
      { key: 'serial_number', width: 5 },
      { key: 'vertical', width: 20 },
      { key: 'account', width: 20 },
      { key: 'project', width: 25 },
      { key: 'workstream', width: 25 },
      { key: 'category', width: 15 },
      { key: 'risk_description', width: 40 },
      { key: 'consequence', width: 40 },
      { key: 'measure', width: 40 },
      { key: 'likelihood', width: 15 },
      { key: 'impact', width: 15 },
      { key: 'risk_level', width: 15 },
      { key: 'risk_response', width: 25 },
      { key: 'risk_response_details', width: 40 },
      { key: 'risk_owner', width: 20 },
      { key: 'risk_status', width: 15 },
      { key: 'eta', width: 15 },
      { key: 'updatedAt', width: 20 },
    ]
    selectedRisksData.forEach((row, i) => {
      const newRow = worksheet.addRow({
        serial_number: i + 1,
        vertical: row.vertical,
        account: row.account,
        project: row.project,
        workstream: row.workstream,
        category: row.category,
        risk_description: row.risk_description,
        consequence: row.consequence,
        measure: row.measure,
        likelihood: row.likelihood,
        impact: row.impact,
        risk_level: row.risk_level,
        risk_response: row.risk_response,
        risk_response_details: row.risk_response_details,
        risk_owner: row.risk_owner,
        risk_status: row.risk_status,
        eta: row.eta,
        updatedAt: row.updatedAt,
      })
      newRow.eachCell((cell) => {
        cell.font = { name: 'Poppins', size: 9 }
        cell.alignment = { vertical: 'middle', horizontal: 'left' }
      })
    })

    const applyConditionalFormatting = (cell, value) => {
      let fillColor
      switch (value.toLowerCase()) {
        case 'critical':
          fillColor = 'E10000';
          break;
        case 'high':
          fillColor = 'EDB13C';
          break;
        case 'medium':
          fillColor = 'F2FB21';
          break;
        case 'low':
          fillColor = '96BD49';
          break;
        case 'very low':
          fillColor = '00A86B';
          break;
        default:
          fillColor = 'FFFFFF'; 
      }

      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: fillColor },
      }
      cell.font = { bold: true }
    }

    selectedRisksData.forEach((row, index) => {
      const currentRow = worksheet.getRow(index + 3)
      applyConditionalFormatting(
        currentRow.getCell('likelihood'),
        row.likelihood
      )
      applyConditionalFormatting(currentRow.getCell('impact'), row.impact)
      applyConditionalFormatting(
        currentRow.getCell('risk_level'),
        row.risk_level
      )
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
    saveAs(blob, 'Risks.xlsx')
  }

  useEffect(() => {
    getMetaData()
    dispatch(fetchRiskOptions()).unwrap()
  }, [])
  useEffect(() => {
    setUniqueVertical(_.uniq(_.map(currentData, 'vertical'))?.sort())
    setUniqueAccount(_.uniq(_.map(currentData, 'account'))?.sort())
  }, [currentData])

  const handleReadmeOpen = () => {
    setOpenReadme(true)
  }

  const handleReadmeClose = () => {
    setOpenReadme(false)
  }

  useEffect(() => {
    dispatch(updateSelectedRisksData(selectedRows?.length > 0 ? selectedRows : rows));
  }, [selectedRows, rows]);
  
  const handleSelectAllRows = () => {
    if (selectedRows.length === rows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows);
    }
  };

  const handleSelectRow = (rowData) => {
    const isSelected = selectedRows.includes(rowData);
    if (isSelected) {
      setSelectedRows(selectedRows.filter(selectedRow => selectedRow !== rowData));
    } else {
      setSelectedRows([...selectedRows, rowData]);
    }
  };

  const columns = useMemo(()=>[
    {
      name: 'checkbox',
      label: '',
      options: {
        filter: false,
        sort: false,
        empty: true,
        download: false,
        customHeadLabelRender: () => {
          const isIndeterminate = selectedRows.length > 0 && selectedRows.length < rows.length;
          return (
            <div style={{ textAlign: 'center',width:'5%', margin:'0px 0px 0px -5px' }} >
              <Checkbox
                checked={rows.length>0 && selectedRows.length === rows.length}
                indeterminate={isIndeterminate}
                onChange={handleSelectAllRows}
                disabled={rows.length === 0} 
              />
            </div>
          );
        },
        customBodyRenderLite: (dataIndex) => {
          return (
            <div style={{ textAlign: 'center', width:'5%', margin:'0px 1px 0px -5px' }}>
              <Checkbox
                checked={selectedRows.includes(rows[dataIndex])}
                onChange={() => handleSelectRow(rows[dataIndex])}
              />
            </div>
          );
        },
      },
    },
    {
      label: 'Vertical',
      name: 'vertical',
      options: {
        sort: false,
        display: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
      },
    },
    {
      label: 'Account',
      name: 'account',
      options: {
        sort: false,
        display: true,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
      },
    },
    {
      label: 'Project - Workstream',
      name: 'project',
      options: {
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold', margin:'0px 1px 0px -5px' }}>{columnMeta.label}</div>
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const project = tableMeta.rowData[3]
          const workstream = tableMeta.rowData[4]
          return `${project} ${workstream ? `- ${workstream}` : ''}`
        },
      },
    },
    {
      label: 'Workstream',
      name: 'workstream',
      options: {
        sort: false,
        display: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
      },
    },
    {
      label: 'Description',
      name: 'risk_description',
      options: {
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <LightTooltip title={value} arrow placement="top">
              <div
                style={{
                  width: '8rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  cursor: 'pointer',
                }}
              >
                {value}
              </div>
            </LightTooltip>
          )
        },
      },
    },
    {
      label: 'Status',
      name: 'risk_status',
      options: {
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              style={{
                fontWeight: 'bold',
              }}
            >
              {columnMeta.label}
            </div>
          )
        },
      },
    },
    {
      label: 'Category',
      name: 'category',
      options: {
        sort: false,
        display: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
      },
    },
    {
      label: 'Risk Level',
      name: 'risk_level',
      options: {
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
      },
    },
    {
      label: 'Risk Owner',
      name: 'risk_owner',
      options: {
        sort: false,
        customHeadLabelRender: (columnMeta) => {
          return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          const { employeeDetails } = adminData || {};  
          const emailId = tableMeta.rowData[9];         
          const employee = employeeDetails?.find((option) => option?.email_id === emailId);
          const cleanedEmail = emailId?.replace(/(@tredence\.com|\d+|\.)/g, ' ').trim();
          return employee?.employee_name || cleanedEmail || '';
        },
      },
    },
    {
      label: 'Actions',
      name: 'actions',
      filter: false,
      options: {
        sort: false,
        empty: true,
        filter: false,
        download: false,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
              {columnMeta.label}
            </div>
          )
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={flexStyle}>
              <LightTooltip title="Edit Risk">
                <IconButton
                  aria-label="Edit Risk"
                  color="primary"
                  onClick={onEdit(
                    tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
                  )}
                >
                  <Edit />
                </IconButton>
              </LightTooltip>
              {/* window closed open for only admin */}
            </div>
          )
        },
      },
    },
  ],[rows,filterData, selectedRows])

  const fetchRiskdata = () => {
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    dispatch(getRiskrecods(data))
  }

  useEffect(() => {
    fetchRiskdata()
  }, [])

  const filtersData = (data) => {
    let filteredData = data
    if (filterData.vertical && filterData.vertical.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.vertical.includes(item.vertical)
      )
    }
    if (filterData.account && filterData.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account)
      )
    }
    if (filterData.project && filterData.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          filterData.project.includes(item.project)
      )
    }
    if (filterData.vertical.length === 0 && filterData.risk_level.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.risk_level.includes(item.risk_level)
      )
    }
    if (
      filterData.vertical.length > 0 &&
      filterData.risk_level &&
      filterData.risk_level.length > 0
    ) {
      if (filterData.risk_status?.length > 0) {
        filteredData = filteredData.filter(
          (item) =>
            filterData.vertical.includes(item.vertical) &&
            filterData.account.includes(item.account) &&
            filterData.project.includes(item.project) &&
            filterData.risk_level.includes(item.risk_level) &&
            filterData.risk_status.includes(item.risk_status)
        )
      } else {
        filteredData = filteredData.filter(
          (item) =>
            filterData.vertical.includes(item.vertical) &&
            filterData.account.includes(item.account) &&
            filterData.project.includes(item.project) &&
            filterData.risk_level.includes(item.risk_level)
        )
      }
    }
    if (filterData.vertical.length === 0 && filterData.risk_status.length > 0) {
      filteredData = filteredData.filter((item) =>
        filterData.risk_status.includes(item.risk_status)
      )
    }
    if (
      filterData.vertical.length > 0 &&
      filterData.risk_status &&
      filterData.risk_status.length > 0
    ) {
      if (filterData.risk_level?.length > 0) {
        filteredData = filteredData.filter(
          (item) =>
            filterData.vertical.includes(item.vertical) &&
            filterData.account.includes(item.account) &&
            filterData.project.includes(item.project) &&
            filterData.risk_status.includes(item.risk_status) &&
            filterData.risk_level.includes(item.risk_level)
        )
      } else {
        filteredData = filteredData.filter(
          (item) =>
            filterData.vertical.includes(item.vertical) &&
            filterData.account.includes(item.account) &&
            filterData.project.includes(item.project) &&
            filterData.risk_status.includes(item.risk_status)
        )
      }
    }
    return filteredData
  }

  useEffect(() => {
    if (
      (filterData.vertical && filterData.vertical.length > 0) ||
      (filterData.risk_level && filterData.risk_level.length > 0) ||
      (filterData.risk_status && filterData.risk_status.length > 0)
    ) {
      let filteredData = filtersData(riskData)
      setRows(filteredData)
    } else if (
      filterData.vertical.length === 0 ||
      (filterData.risk_level && filterData.risk_level.length > 0) ||
      (filterData.risk_status && filterData.risk_status.length > 0)
    ) {
      setRows(riskData)
    } else {
      setRows(riskData)
    }
  }, [filterData])

  useEffect(() => {
    setRows(riskData)
    dispatch(updateRiskInitialData(riskData))
  }, [riskData])

  const editRow =
    editRowIndex !== null && riskData ? rows[editRowIndex] : {}

  return (
    <>
      <Loading open={openBackdrop} />
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '5px',
          }}
        >
          <Tooltip title={'Download Risks'}>
            <Button
              sx={{
                cursor: 'pointer',
              }}
              onClick={handleDownload}
            >
              <CloudDownloadIcon />
            </Button>
          </Tooltip>
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {riskInitialData.length > 0 && (
                <>
                  <Tooltip
                    title={'Click here to filter the data'}
                    leaveTouchDelay={3000}
                    enterTouchDelay={20}
                  >
                    <FilterListIcon
                      variant="contained"
                      color="primary"
                      onClick={handleClick}
                      sx={{
                      marginRight:'18px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                  <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                  >
                    <Box
                      sx={{
                        p: 1.2,
                        backgroundColor: '#f9f9f9',
                        maxWidth:
                          isSmallScreen || isBetweenSmAndMd ? '300px' : '500px',
                      }}
                    >
                      <FilterComponent
                        setAnchorEl={setAnchorEl}
                        keyName={'riskManagement'}
                      />
                    </Box>
                  </Popper>
                </>
              )}
            </div>
          <Button variant="contained" color="primary" onClick={handleFormOpen}>
            ADD RISK
          </Button>
        </Box>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'end',
            marginBottom: '15px',
            // gap: 0.5,
          }}
        >
          <div>
            <Typography
              variant="body1"
              color="primary"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={handleReadmeOpen}
            >
              ReadMe File
            </Typography>
          </div>
        </Grid>
        <Grid>
          <RiskTable data={rows} columns={columns} selectable={true} setSelectedRows={setSelectedRows} />
        </Grid>
      </Box>

      {openForm && (
        <RiskForm
          open={openForm}
          onClose={onClose}
          metadata={currentData}
          verticals={uniqueVertical}
          accounts={uniqueAccount}
          workstreams={adminData.workstreams}
          employees={adminData.employeeDetails}
          editForm={editForm}
          editRow={editRow}
          user={userDetails}
          fetchRiskdata={fetchRiskdata}
        />
      )}
      {openReadme && (
        <ReadmeFile open={openReadme} onClose={handleReadmeClose} />
      )}
    </>
  )
}

export default RiskManagement
