import {
  Button,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import PRALogo from '../../Assets/Images/PRALogo.png'
import TredenceLogo from '../../Assets/Images/TredenceLogo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    //gap: '9rem',
    minHeight: '100vh',
    maxWidth: '100vw',
    background: 'linear-gradient(to right, #ffffff, #ffece3)',
    padding: '1.5rem',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  prReviewImg: {
    display: 'flex',
    gap: '0.5rem',
    // marginTop: '0.5rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tredenceImg: {
    width: '8rem',
    height: '5rem',
  },
  loginText1: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '1.8rem',
    color: '#000000',
  },
  loginText2: {
    marginBottom: theme.spacing(3),
    fontFamily: 'Poppins',
    fontSize: '1rem',
    opacity: '0.7',
    fontWeight: 100,
    color: '#000000',
  },
  card: {
    maxWidth: '400px',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    fontFamily: 'Poppins',
  },
  loginButton: {
    margin: theme.spacing(2),
    background: '#FE5000',
    color: '#FFFFFF',
    width: '80%',
    fontFamily: 'Poppins',
  },
  loginDiv: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontFamily: 'Poppins',
  },
}))

function DevUserLogin(props) {
  const users = [
    {
      name: 'Payal Jain',
      email: 'payal.jain@tredence.com',
    },
    {
      name: 'Indu Prasad',
      email: 'kumar.gaurav@tredence.com',
    },
    {
      name: 'Nagendra Varma',
      email: 'priyanshu.kumar@tredence.com',
    },
    {
      name: 'Ranga Y',
      email: 'ranga.y@tredence.com',
    },
    {
      name: 'Soumya',
      email: 'soumya.huded@tredence.com',
    },
    {
      name: 'Sunanda',
      email: 'sunanda.konduru@tredence.com',
    },
  ]
  return (
    <div>
      <List component="nav">
        {users.map((user, index) => (
          <div key={user.email}>
            <ListItem
              button
              onClick={() => props.handleLogin(user.email)}
              style={{ fontFamily: 'Poppins' }}
            >
              <ListItemText primary={user.name} secondary={user.email} />
            </ListItem>
            {index !== users.length - 1 && <Divider component="li" />}
          </div>
        ))}
      </List>
    </div>
  )
}

const LoginNew = (props) => {
  const classes = useStyles()
  const [signUpError] = React.useState('')
  const currentYear = new Date().getFullYear()

  return (
    <>
      {signUpError ? (
        <span style={{ color: 'white' }}>{signUpError}</span>
      ) : null}
      <div
        className={classes.root}
        // style={{
        //   display: 'flex',
        //   flexDirection: 'column',
        //   justifyContent: 'space-between',
        //   //gap: '9rem',
        //   minHeight: '100vh',
        //   maxWidth: '100vw',
        //   background: 'linear-gradient(to right, #ffffff, #ffece3)',
        //   padding:'1.5rem'
        // }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            className={classes.prReviewImg}
            // style={{
            //   display: 'flex',
            //   gap: '0.5rem',
            //   // marginTop: '0.5rem',
            //   justifyContent: 'flex-start',
            //   alignItems: 'center',
            // }}
          >
            <img src={PRALogo} height={40} alt="img" />
            <div className="title">
              <p style={{ color: '#123D81' }}>Project Review</p>
              <pre className="fontweightChange">the Value Score</pre>
            </div>
          </div>
          <div>
            <img
              src={TredenceLogo}
              className={classes.tredenceImg}
              alt="Tredence"
            />
          </div>
        </div>
        <div className={classes.flexBox}>
          <div
            className={classes.loginDiv}
            // style={{
            //   textAlign: 'center',
            //   display: 'flex',
            //   flexDirection: 'column',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            //   flexWrap: 'wrap',
            //   fontFamily: 'Poppins',
            // }}
          >
            <Typography
              variant="h4"
              className={classes.loginText1}
              // style={{
              //   fontWeight: 600,
              //   fontFamily: 'Poppins',
              //   fontSize: '1.8rem',
              // }}
            >
              Login into your account
            </Typography>
            <Typography variant="h6" className={classes.loginText2}>
              Know your project stats better
            </Typography>
          </div>
          <Card className={classes.card}>
            {process.env.NODE_ENV === 'development' && (
              <DevUserLogin handleLogin={props.handleLogin} />
            )}
            <CardContent className={classes.cardContent}>
              <Typography
                variant="subtitle1"
                style={{ fontFamily: 'Poppins', fontWeight: '500' }}
              >
                Click the button to login
              </Typography>
              <Button
                variant="contained"
                className={classes.loginButton}
                onClick={props.handleLogin}
                disabled={props.isLoading}
              >
                {props.isLoading ? 'Logging in...' : 'SSO Log In'}
              </Button>
              <Typography
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '0.8rem',
                  fontWeight: '300',
                }}
                variant="caption"
                color="textSecondary"
                align="center"
              >
                Please reach out to <b>deliveryexcellence@tredence.com</b> for
                support
              </Typography>
            </CardContent>
          </Card>
        </div>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          style={{ fontFamily: 'Poppins', fontSize: '0.7rem' }}
        >
          &copy; Copyright {currentYear}. Tredence.Inc. All rights reserved
        </Typography>
      </div>
    </>
  )
}

export default LoginNew
