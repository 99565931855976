import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/EditTwoTone'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterListIcon from '@mui/icons-material/FilterList'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  PaginationItem,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Popper from '@mui/material/Popper'
import Select from '@mui/material/Select'
import { ThemeProvider, createTheme, styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import MUIDataTable from 'mui-datatables'
import { useSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../../Common/Loading'
import {
  fetchCurrentWeekMetaData,
  fetchFilteredData,
  fetchMoMData,
  getAllWeeksProjectHealthData,
  getProjectList,
  saveData,
  saveMoMData,
} from '../../../../redux/reducer/prHealthSlice'
import {
  updateCount,
  updateExcludeData,
  updateFilterData,
  updateWeekData,
  updateWeeklyHealthInitialData,
  updateYearData,
  updateisApiCallMade,
} from '../../../../redux/reducer/projectHealthFilterSlice'
import FilterComponent from '../Common/multiSelectCustomFilter'
import {
  getFriday,
  getTotalDaysByVaule,
  getWeekDate,
  getWeekNumber,
} from '../Common/utils/commonFunc'

import {
  Table,
  TableBody,
  TableContainer,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Grid } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {
  getRiskByProject,
  fetchRiskOptions,
} from '../../../../redux/reducer/riskSlice'
import { getUpdatedBy } from '../../../../services/prHealthServices'
import styles from '../../../../Styles/prHealthStyles.module.scss'
import RiskForm from '../../RiskLogs/RiskForm'
import RiskTable from '../../RiskLogs/Risktable'
import { getAllMetaData } from '../../../../services/riskManagementService'

const getStyleValue = (value) => {
  let styleValue
  if (value === 'AMBER') {
    styleValue = {
      backgroundColor: '#ffdd76',
    }
  } else if (value === 'GREEN') {
    styleValue = { backgroundColor: '#33D27E42' }
  } else if (value === 'RED') {
    styleValue = { backgroundColor: '#f08080' }
  } else {
    styleValue = { background: 'none' }
  }
  return styleValue
}

const flexStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const DataTable = ({ statusKey }) => {
  return (
    <TableContainer className={styles.headerStatus}>
      <Table aria-label="simple table">
        <TableBody>
          {colors.map((color, rowIndex) => {
            const value = statusInfo?.[statusKey]?.[color]
            const styleValue = getStyleValue(color)
            return (
              <TableRow key={rowIndex}>
                <TableCell key={rowIndex} style={styleValue}>
                  {value}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const useStyles = makeStyles({
  option: {
    fontSize: '12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
  toolbarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // marginTop: '-42px'
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  searchInput: {
    fontSize: '14.5px',
    marginLeft: '8px',
    marginRight: '8px',
    width: '200px',
    borderBottom: '2px solid #F58220',
  },
  searchIcon: {
    cursor: 'pointer',
    color: '#F58220',
  },
})

function formatDate(date) {
  const options = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }
  return date.toLocaleString('en-US', options)
}

const colors = ['RED', 'AMBER', 'GREEN']
const reasons = [
  'Project is on hold / yet to start',
  'Project is closed/lost',
  'Extension of Existing Project',
  'Others',
]
const statuses = [
  'project_overrun',
  'cs_escalation',
  'delivery_escalation',
  'customer_escalations',
]
const statusInfo = {
  customer_escalations: {
    RED: 'Client has already escalated one or multiple times',
    AMBER:
      'Client has raised a minor concern, but things are under control, and we have a plan in place to address the concern',
    GREEN: 'Client is happy',
  },
  project_overrun: {
    RED: 'There are clear deviations in scope, schedule, cost , buffer for reasons that should have been avoided',
    AMBER:
      'There are deviations in scope, schedule, cost , buffer  for reasons that are either client-dependable or are our strategic stand.',
    GREEN: 'Scope,Schedule, Cost & Buffer are all as planned',
  },
  cs_escalation: {
    RED: 'CS escalated an issue and needs immediate intervention to fix it.',
    AMBER:
      'CS has raised a concern. But the things are under control and a plan in place visible to CS to control the situation',
    GREEN: 'CS is aligned in all aspects.',
  },
  delivery_escalation: {
    RED: 'Delivery lead has raised a concern on scope, schedule, resourcing, quality areas that need immediate intervention',
    AMBER:
      'Delivery lead sees a deviation that can potentially become a red flag in future.',
    GREEN: 'Delivery is going well.',
  },
}
const currentYear = new Date().getFullYear()
const years = []
for (let yearData = 2023; yearData <= currentYear; yearData++) {
  years.push(yearData)
}

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    leaveTouchDelay={3000}
    enterTouchDelay={20}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
    fontWeight: 600,
    maxWidth: 160,
    borderRadius: 3,
  },
}))

const CustomHeader = ({ columnMeta, msg }) => {
  return (
    <div
      style={{
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // marginLeft: '4px',
        padding: 0,
        fontSize: '12px',
        textAlign: 'center',
      }}
    >
      {columnMeta.label}
      <LightTooltip title={msg} placement="bottom">
        <IconButton sx={{ padding: 0 }}>
          <InfoOutlinedIcon sx={{ height: '14px' }} />
        </IconButton>
      </LightTooltip>
    </div>
  )
}

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    leaveTouchDelay={3000}
    enterTouchDelay={20}
    classes={{ popper: className }}
    componentsProps={{
      tooltip: {
        sx: {
          width: {
            xs: 200,
            sm: 200,
            md: 300,
            lg: 400,
            xl: 500,
          },
          border: '1px solid black',
          borderRadius: 0,
          placement: {
            xs: 'top',
            sm: 'center',
            md: 'right',
            lg: 'right',
            xl: 'top',
          },
          padding: 0,
        },
      },
    }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    // maxWidth: '30vw',
    // border:'1px solid black',
    // borderRadius:0,
    padding: 0,
  },
})

const CustomStatusHeader = ({ columnMeta }) => {
  return (
    <div
      style={{
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: '12px',
      }}
    >
      <CustomWidthTooltip title={<DataTable statusKey={columnMeta?.name} />}>
        <span style={{ cursor: 'pointer' }}>{columnMeta.label}</span>
      </CustomWidthTooltip>
    </div>
  )
}

// const CustomStatusHeader = ({ columnMeta }) => {
//   const [openInfo, setOpenInfo] = useState(false)
//   const handleClose = () => {
//     setOpenInfo(false)
//   }
//   const handleOpen = () => {
//     setOpenInfo(true)
//   }

//   return (
//     <>

// <CustomWidthTooltip style={{ maxWidth: 40 }} title={<DataTable statusKey={columnMeta?.name} />} placement="right">
//         <IconButton>
//           <RemoveRedEyeOutlinedIcon style={{fontSize:'12px'}}/>
//         </IconButton>
//       </CustomWidthTooltip>

//     {/* <Dialog
//     open={openInfo}
//     onClose={handleClose}
//     keepMounted
//     aria-describedby="alert-dialog-slide-description"
//   >
//         <DialogContent sx={{ minWidth: '600px' }}>
//         <DialogContentText
//           id="alert-dialog-slide-description"
//           sx={{ color: '#000000', fontSize: '14px' }}
//         >
//           <DataTable statusKey={columnMeta?.name} status={columnMeta?.label}/>
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button
//           onClick={handleClose}
//           variant="outlined"
//           sx={{ width: '90px', fontSize: '12px' }}
//         >
//           Close
//         </Button>
//       </DialogActions>
//   </Dialog> */}
//   </>
//   )
// }

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}))

const primary = '#F58220'
const secondary = '#F58220'
const common = '#ffa500'

const getMuiTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      common: {
        main: common,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins, sans-serif !important',
            fontSize: '12px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '0px 3.5px',
            lineHeight: '1.4rem',
            textAlign: 'left',
            fontFamily: 'Poppins',
            fontSize: '12px',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&:nth-of-type(odd)': {
              backgroundColor: '#F5F5F5',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontFamily: 'Poppins, Roboto',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontFamily: 'Poppins, Roboto',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: '12px',
            fontFamily: 'Poppins, Roboto',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          option: {
            fontSize: '0.725rem',
            fontFamily: 'Poppins, Roboto',
          },
        },
      },
    },
  })

const IssuesDialog = ({
  data,
  handleModification,
  handleClose,
  roleAccess,
  index,
  minWidth = { minWidth },
}) => {
  // const { enqueueSnackbar: notify } = useSnackbar()
  const [values, setValues] = useState({
    issues: data?.issues || '',
    preventive_actions: data?.preventive_actions || '',
    corrective_actions: data?.corrective_actions || '',
  })

  // const [prjOverrunReason, setPrjOverrunReason] = useState(
  //   data?.financial_impact || ''
  // )
  const weekNumber = getWeekNumber(currentYear)
  // const projectOverrun = data?.project_overrun?.toUpperCase()
  // const handleChange = (event) => {
  //   setPrjOverrunReason(event.target.value)
  // }

  const handleSave = (e) => {
    let saveValues = JSON.parse(JSON.stringify(values))
    // if (projectOverrun === 'AMBER' || projectOverrun === 'RED') {
    //   if (prjOverrunReason) {
    //     saveValues = {
    //       ...saveValues,
    //       financial_impact: prjOverrunReason,
    //     }
    //     handleModification(e, data, index, 'healthStatus', saveValues)
    //   } else {
    //     notify(
    //       `Please select the financial impact since project overrun is ${projectOverrun}!`,
    //       {
    //         variant: 'info',
    //       }
    //     )
    //     return
    //   }
    // } else {
    //   handleModification(e, data, index, 'healthStatus', saveValues)
    // }
    handleModification(e, data, index, 'healthStatus', saveValues)
  }

  return (
    <>
      <DialogTitle
        sx={{
          padding: 0,
          marginBottom: '10px',
          color: '#000000',
          fontSize: '12px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <b>Vertical</b> : {data?.vertical}
          <br />
          <b>Account</b> : {data?.account}
          <br />
          <b>Project</b> : {data?.project}
          <br />
          <b>Workstream</b> : {data?.workstream}
        </div>
      </DialogTitle>
      <DialogContent
        id="alert-dialog-slide-description"
        sx={{ color: '#000000', fontSize: '14px', padding: 0, minWidth }}
      >
        {/* {(projectOverrun === 'RED' || projectOverrun === 'AMBER') && (
          <div>
            <h5>Reason for project overrun * :</h5>
            <RadioGroup
              value={prjOverrunReason}
              onChange={handleChange}
              row
              sx={{ paddingBottom: '10px' }}
            >
              <FormControlLabel
                value="CR discussion is underway"
                control={<Radio size="small" />}
                label="CR discussion is underway"
                sx={{ marginBottom: -1 }}
                disabled={weekNumber !== data?.week_no || !roleAccess}
              />
              <FormControlLabel
                value="Tredence is obliged to bear the financial impact"
                control={<Radio size="small" />}
                label="Tredence is obliged to bear the financial impact"
                sx={{ marginBottom: -1 }}
                disabled={weekNumber !== data?.week_no || !roleAccess}
              />
              <FormControlLabel
                value="Client has not agreed to the CR and Tredence will bear the financial impact"
                control={<Radio size="small" />}
                label="Client has not agreed to the CR and Tredence will bear the financial impact"
                //sx={{ marginBottom: -1 }}
                disabled={weekNumber !== data?.week_no || !roleAccess}
              />
            </RadioGroup>
          </div>
        )} */}
        <Grid style={{ margin: '2px' }}>
          <h5>Issue * :</h5>
          <textarea
            id="outlined-helperText"
            placeholder="Issues"
            variant="outlined"
            className={styles.issueTextArea}
            value={values?.issues}
            disabled={weekNumber !== data?.week_no || !roleAccess}
            onChange={(e) =>
              setValues((prev) => ({ ...prev, issues: e.target.value }))
            }
            multiline
          ></textarea>
        </Grid>
        <Grid style={{ margin: '2px' }}>
          <h5>Corrective Actions * :</h5>
          <textarea
            id="outlined-helperText"
            placeholder="Enter corrective actions"
            variant="outlined"
            className={styles.issueTextArea}
            defaultValue={values?.corrective_actions}
            disabled={weekNumber !== data?.week_no || !roleAccess}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                corrective_actions: e.target.value,
              }))
            }
            multiline
          ></textarea>
        </Grid>
        <Grid style={{ margin: '2px' }}>
          <h5>Preventive Actions * :</h5>
          <textarea
            id="outlined-helperText"
            placeholder="Enter preventive actions"
            variant="outlined"
            className={styles.issueTextArea}
            value={values?.preventive_actions}
            disabled={weekNumber !== data?.week_no || !roleAccess}
            onChange={(e) =>
              setValues((prev) => ({
                ...prev,
                preventive_actions: e.target.value,
              }))
            }
            multiline
          ></textarea>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          sx={{ width: '90px', fontSize: '12px' }}
          onClick={handleSave}
          disabled={
            !(
              roleAccess &&
              values?.preventive_actions &&
              values?.corrective_actions &&
              values?.issues
            )
          }
        >
          Save
        </Button>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{ width: '90px', fontSize: '12px' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

const WorkstreamDialog = ({
  data,
  updatedRows,
  rows,
  setEditing,
  setSelected,
  setUpdatedRows,
  setWorkstreamOpen,
  index,
  weekNumber,
  year,
  userDetails,
  minWidth,
}) => {
  const [workstreamVal, setWorkstreamVal] = useState('')
  const [existingWorkstream, setExistingWorkstream] = useState('')
  const { weeklyHealthInitialData } = useSelector((state) => state.filter)

  const handleChange = (e) => {
    setWorkstreamVal(e.target.value)
  }

  // handle close workstream dialog
  const handleCloseWorkstream = (e) => {
    e.preventDefault()
    setWorkstreamVal('')
    setExistingWorkstream('')
    setWorkstreamOpen(false)
  }

  const saveUpdateWorkstream = (e) => {
    e.preventDefault()
    setEditing(true)
    const rowdata = JSON.parse(JSON.stringify(updatedRows))
    const currentData = data
    const modifiedRow = rowdata.filter(
      (d) => d.unique_id == currentData?.unique_id
    )[0]
    const value = workstreamVal?.trim()?.replaceAll(' ', '')
    const isExistingWorkstream =
      JSON.parse(JSON.stringify(weeklyHealthInitialData)).filter(
        (data) =>
          data.account == currentData?.account &&
          data.project == currentData?.project &&
          data.vertical == currentData?.vertical &&
          data.workstream?.toLowerCase()?.replaceAll(' ', '') ==
            value?.toLowerCase()
      )?.length ||
      JSON.parse(JSON.stringify(rowdata)).filter(
        (data) =>
          data.account == currentData?.account &&
          data.project == currentData?.project &&
          data.vertical == currentData?.vertical &&
          data.workstream?.toLowerCase()?.replaceAll(' ', '') ==
            value?.toLowerCase()
      )?.length
    const currentDate = formatDate(new Date())
    const userEmail = userDetails?.email_id
    if (isExistingWorkstream) {
        setExistingWorkstream(
          'The workstream already exists for the selected account and project.'
        )
    } else if (!value) {
      setExistingWorkstream('Please provide the workstream name')
    } else {
      if (modifiedRow?.workstream) {
        const newRow = JSON.parse(JSON.stringify(modifiedRow))
        const reqValues = [
          'customer_escalations',
          'preventive_actions',
          'corrective_actions',
          'issues',
          'project_overrun',
          'cs_escalation',
          'delivery_escalation',
          'exclude',
          'MoM',
          'health_status',
          'reason_for_exclusion',
        ]
        Object.keys(newRow).map((record) =>
          reqValues.includes(record)
            ? (newRow[record] = '')
            : (newRow[record] = newRow[record])
        )
        newRow['workstream'] = workstreamVal
        newRow['risk_count'] = ''
        newRow['newFlag'] = true
        newRow['exclude'] = 'No'
        newRow['unique_id'] = `${data?.unique_id}-${value}`
        newRow['week_no'] = weekNumber
        newRow['year'] = year
        newRow['updatedBy'] = userEmail
        newRow['updated_on'] = currentDate
        newRow['week'] = `WE${getWeekDate(weekNumber, year)
          .replace(' ', '-')
          .replace(' ', '')}`
        rowdata.splice(index + 1, 0, newRow)
        setUpdatedRows(rowdata)
        setSelected((prev) => ({
          ...prev,
          [`${data?.unique_id}-${value}`]: newRow,
        }))
      } else {
        modifiedRow['workstream'] = workstreamVal
        modifiedRow['updatedBy'] = userEmail
        modifiedRow['updated_on'] = currentDate
        modifiedRow['updateWS'] = true
        rowdata[index] = modifiedRow
        setUpdatedRows(rowdata)
        setSelected((prev) => ({
          ...prev,
          [currentData?.unique_id]: modifiedRow,
        }))
      }
      setWorkstreamOpen(false)
      setExistingWorkstream('')
      setWorkstreamVal('')
    }
  }

  return (
    <>
      <DialogContent sx={{ minWidth }}>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ color: '#000000', fontSize: '14px' }}
        >
          <h5
            style={{ marginBottom: '8px' }}
          >{`New Workstream for Project : ${data?.project} (${data?.account} )`}</h5>
          <TextField
            value={workstreamVal || ''}
            //changedValues?.workstream || ''}
            fullWidth
            placeholder="Type new workstream name"
            onChange={handleChange}
          />
          {existingWorkstream && (
            <h5 style={{ marginTop: '8px', color: 'red' }}>
              {existingWorkstream}
            </h5>
          )}
          {data?.risk_count >= 1 && !data?.workstream && (
            <h5 style={{ marginTop: '8px' }}>
              <span style={{ color: 'red' }}>Alert:</span> Risks are logged
              against this project
            </h5>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          sx={{ width: '90px', fontSize: '12px' }}
          onClick={saveUpdateWorkstream}
        >
          Add
        </Button>
        <Button
          onClick={handleCloseWorkstream}
          variant="outlined"
          sx={{ width: '90px', fontSize: '12px' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

const MoMDialog = ({
  history,
  weekNumber,
  page,
  userDetails,
  currentMoM,
  data,
  handleActionClose,
  minWidth,
}) => {
  const dispatch = useDispatch()
  const [momData, setMomdata] = useState()

  useEffect(() => {
    currentMoM ? setMomdata(currentMoM) : setMomdata('')
  }, [currentMoM])

  const handleMoMTextChange = (event) => {
    setMomdata(event.target.value)
  }

  const handleClose = () => {
    currentMoM ? setMomdata(currentMoM) : setMomdata('')
    handleActionClose()
  }

  async function saveMOM() {
    const currentDate = formatDate(new Date())
    dispatch(
      saveMoMData({
        ...data,
        MoM: momData,
        updated_on: currentDate,
        updatedBy: userDetails?.email_id,
      })
    ).then(() => handleClose())
  }

  return (
    <>
      <DialogContent sx={{ minWidth }}>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ color: '#000000', fontSize: '14px' }}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>MoM History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {history?.length !== 0
                ? history?.map((rec) => {
                    return (
                      <>
                        <h5>
                          {`${rec?.week} ${rec?.week_date || ''} ${rec.year} :`}
                        </h5>
                        <p className="wrap">{rec?.MoM}</p>
                        <br />
                      </>
                    )
                  })
                : 'No records to display'}
            </AccordionDetails>
          </Accordion>
          {page === weekNumber && (
            <textarea
              id="outlined-helperText"
              placeholder={
                userDetails?.role != 'Admin'
                  ? 'No MoMs are recorded by Admin for this week'
                  : 'Enter minutes of meeting (MOM)'
              }
              variant="outlined"
              style={{
                width: '100%',
                height: '240px',
                resize: 'none',
                padding: '5px',
                marginTop: '15px',
              }}
              value={momData}
              onChange={handleMoMTextChange}
              multiline
              disabled={userDetails?.role !== 'Admin'}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {page === weekNumber && userDetails?.role === 'Admin' && (
          <Button
            color="primary"
            variant="contained"
            sx={{ width: '90px', fontSize: '12px' }}
            onClick={saveMOM}
          >
            Save
          </Button>
        )}
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{ width: '90px', fontSize: '12px' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

const OthersBox = ({
  updatedRows,
  setUpdatedRows,
  setSelected,
  allProjectsData,
  projects,
  data,
  index,
  userDetails,
  setReasonOn,
  week_no,
  week,
  year,
  minWidth,
  weeklyHealthInitialData,
  allWeeksProjectHealthDta,
}) => {
  const [error, setError] = useState(false)
  const [reasonOfExclusionSelect, setReasonOfExclusionSelect] = useState('')
  const [existingProjectVal, setExistingProjectVal] = useState('')
  const [existingWorkstreamVal, setExistingWorkstreamVal] = useState('')
  const [workstreams, setWorkStreams] = useState([])
  const [exclusionReason, setExclusionReason] = useState('')
  const { enqueueSnackbar: notify } = useSnackbar()
  const classes = useStyles()

  const handleChange = (e) => {
    setExclusionReason(e.target.value)
    setError(false)
  }

  // collect the reason for exclusion
  const saveExludeReason = (e) => {
    e.preventDefault()
    if (reasonOfExclusionSelect === 'Others' && !exclusionReason?.trim()) {
      setError(true)
      setReasonOn(true)
    } else if (
      reasonOfExclusionSelect === 'Extension of Existing Project' &&
      !existingProjectVal
    ) {
      setError(true)
      setReasonOn(true)
    } else {
      const rowdata = JSON.parse(JSON.stringify(updatedRows))
      const modifiedRow = rowdata.filter(
        (d) => d.unique_id === data?.unique_id
      )[0]

      const value =
        reasonOfExclusionSelect === 'Others'
          ? `${reasonOfExclusionSelect} : ${exclusionReason}`
          : reasonOfExclusionSelect === 'Extension of Existing Project'
          ? `Extension of Existing Project : ${existingProjectVal} , workstream : ${existingWorkstreamVal}`
          : reasonOfExclusionSelect
      const matchedItem = weeklyHealthInitialData.find(
        (item) => item.unique_id === modifiedRow.unique_id
      )
      let unfilledItem
      if (
        (!modifiedRow?.newFlag && !matchedItem?.health_status) ||
        matchedItem?.health_status == 'Provide Inputs'
      ) {
        unfilledItem = allWeeksProjectHealthDta.filter(
          (item) => item.unique_id === modifiedRow.unique_id
        )[0]
      }

      if (unfilledItem) {
        modifiedRow['red_status_count'] =
          unfilledItem?.redStatusCount != null &&
          unfilledItem?.redStatusCount > 0
            ? unfilledItem?.redStatusCount
            : 0
        modifiedRow['amber_status_count'] =
          unfilledItem?.amberStatusCount != null &&
          unfilledItem?.amberStatusCount > 0
            ? unfilledItem?.amberStatusCount
            : 0
        modifiedRow['green_status_count'] =
          unfilledItem?.greenStatusCount != null &&
          unfilledItem?.greenStatusCount > 0
            ? unfilledItem?.greenStatusCount
            : 0
        modifiedRow['status_count'] =
          unfilledItem?.totalStatusCount != null &&
          unfilledItem?.totalStatusCount > 0
            ? unfilledItem?.totalStatusCount
            : 0
      }

      if (modifiedRow?.newFlag == true) {
        modifiedRow.red_status_count = 0
        modifiedRow.green_status_count = 0
        modifiedRow.amber_status_count = 0
        modifiedRow.status_count = 0
      }

      modifiedRow['exclude'] = 'Yes'
      modifiedRow['reason_for_exclusion'] = value
      statuses.map((status) => (modifiedRow[status] = ''))
      modifiedRow['health_status'] = 'Excluded from tracking'
      modifiedRow['updatedBy'] = userDetails?.email_id
      modifiedRow['updated_on'] = formatDate(new Date())
      rowdata[index] = modifiedRow
      if (!modifiedRow.reason_for_exclusion && data?.exclude === 'Yes') {
        notify(`Please provide reason for exclusion`, {
          variant: 'info',
        })
        setReasonOn(true)
      } else {
        modifiedRow.week_no = week_no
        modifiedRow.year = year
        modifiedRow.week = week
        rowdata[index] = modifiedRow
        setReasonOn(false)
        setUpdatedRows(rowdata)
        setSelected((prev) => ({
          ...prev,
          [data?.unique_id]: modifiedRow,
        }))
      }
      setExclusionReason('')
      setReasonOfExclusionSelect('')
      setExistingProjectVal('')
      setExistingWorkstreamVal('')
      setWorkStreams([])
      setError(false)
    }
  }

  // handle close of the reason for exclusion dialog
  const handleCloseExclude = (e) => {
    const rowdata = JSON.parse(JSON.stringify(updatedRows))
    const modifiedRow = rowdata[index]
    modifiedRow['reason_for_exclusion'] = ''
    modifiedRow['exclude'] = 'No'
    rowdata[index] = modifiedRow
    setUpdatedRows(rowdata)
    setSelected((prev) => ({
      ...prev,
      [data?.unique_id]: modifiedRow,
    }))
    e.preventDefault()
    setExclusionReason('')
    setReasonOfExclusionSelect('')
    setExistingProjectVal('')
    setExistingWorkstreamVal('')
    setWorkStreams([])
    setError(false)
    setReasonOn(false)
  }

  const handleReasonForExclusion = (e) => {
    setReasonOfExclusionSelect(e.target.value)
  }

  const handleTextChange = (value) => {
    setExistingProjectVal(value)
  }

  const handleWorkstreamTextChange = (e) => {
    setExistingWorkstreamVal(e.target.value)
  }

  useEffect(() => {
    if (existingProjectVal) {
      setError(false)
      let workStreamData = JSON.parse(JSON.stringify(allProjectsData)).filter(
        (data) => data.project == existingProjectVal
      )
      setWorkStreams([
        ...new Set(workStreamData.map((record) => record?.workstream)),
      ])
    }
  }, [existingProjectVal])

  return (
    <>
      <DialogContent sx={{ minWidth }}>
        <DialogContentText
          id="alert-dialog-slide-description"
          sx={{ color: '#000000', fontSize: '14px' }}
        >
          <h5 style={{ marginBottom: '15px' }}>{`${data?.project} - ${
            data?.workstream || ''
          }  (${data?.account})`}</h5>
          <h5>Reason for Exclusion</h5>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={reasonOfExclusionSelect || ''}
            onChange={handleReasonForExclusion}
            size="small"
          >
            {reasons.map((reason) => (
              <MenuItem value={reason}>{reason}</MenuItem>
            ))}
          </Select>
          {reasonOfExclusionSelect == 'Others' && (
            <>
              <textarea
                id="outlined-helperText"
                placeholder="Enter Reason for exclusion"
                variant="outlined"
                style={{
                  width: '100%',
                  height: '100px',
                  resize: 'none',
                  padding: '5px',
                  marginTop: '15px',
                  borderColor: error ? 'red' : '',
                }}
                defaultValue={exclusionReason || ''}
                onChange={handleChange}
              />
              {error && (
                <p style={{ color: 'red', fontSize: '11px' }}>
                  Please enter a reason for exclusion.
                </p>
              )}
            </>
          )}
          {reasonOfExclusionSelect == 'Extension of Existing Project' && (
            <>
              <h5 style={{ marginTop: '15px' }}>Parent/Associated Project</h5>
              <Autocomplete
                classes={{
                  listbox: classes.option,
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    padding: '2px 2px 2px 8px',
                    height: '35px',
                  },
                }}
                value={existingProjectVal || null}
                onChange={(e, newValue) => handleTextChange(newValue)}
                name="existing_project"
                options={projects.sort()}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField {...params} label="" variant="outlined" />
                )}
                autoHighlight
                fullWidth
              />
              {error && (
                <p style={{ color: 'red', fontSize: '11px' }}>
                  Please specify the existing project
                </p>
              )}
              {existingProjectVal && (
                <>
                  <h5 style={{ marginTop: '15px' }}>Select workstream</h5>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    fullWidth
                    value={existingWorkstreamVal}
                    onChange={handleWorkstreamTextChange}
                    size="small"
                  >
                    {workstreams.map((workstream) => (
                      <MenuItem value={workstream}>{workstream}</MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          sx={{ width: '90px', fontSize: '12px' }}
          onClick={saveExludeReason}
        >
          Save
        </Button>
        <Button
          onClick={handleCloseExclude}
          variant="outlined"
          sx={{ width: '90px', fontSize: '12px' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

function WeeklyHealthView({ editing, setEditing }) {
  const dispatch = useDispatch()
  const { enqueueSnackbar: notify } = useSnackbar()
  const [filter, setfilter] = useState({})
  const [rows, setRows] = useState([])
  const [updatedRows, setUpdatedRows] = useState([])
  const [reasonOn, setReasonOn] = useState(false)
  const [workstreamOpen, setWorkstreamOpen] = useState(false)
  const [otherInfoOpen, setOtherInfoOpen] = useState(false)
  const [otherInfo, setOtherInfo] = useState('')
  const [selected, setSelected] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [dialogContent, setDialogContent] = useState('')
  const [openAction, setOpenAction] = useState(false)
  const [conditions, setConditions] = useState({})
  const [loading, setLoading] = useState(false)
  const weekNumber = getWeekNumber(currentYear)
  const { userDetails } = useSelector((state) => state.user)
  const [anchorEl, setAnchorEl] = useState(null)
  const [saveInprogress,setSaveInprogress] = useState(false)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const {
    weeklyHealthInitialData,
    filterData,
    page,
    year,
    exclude,
    isApiCalHappened,
    count,
  } = useSelector((state) => state.filter)
  const { allWeeksProjectHealthDta } = useSelector((state) => state.prHealth)
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const [workstreamContent, setWorkstreamContent] = useState('')
  const [momContent, setMoMContent] = useState('')
  const [reasonContent, setReasonContent] = useState('')
  const [search, setSearch] = useState(false)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery('(max-width: 769px)')
  const isxsScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'))
  const [viewRisk, setViewRisk] = useState(false)
  const [currentRisk, setCurrentRisk] = useState([])
  const [currentRiskData, setCurrentRiskData] = useState([])
  const [openEditRisk, setOpenEditRisk] = useState(false)
  const [addRisk, setAddRisk] = useState(false)
  const [metadata, setMetadata] = useState([])
  const [riskData, setRiskData] = useState([])
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { isLoadingRiskForPrj, errorFetchRiskForPrj, riskDataForPrj } =
    useSelector((state) => state.risk)
  const { data: adminData } = useSelector((state) => state.admin)

  const riskColumns = useMemo(
    () => [
      {
        name: '#',
        label: '#',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRender: (value, tableMeta) => {
            return (
              <div style={{ paddingLeft: '10px' }}>
                {tableMeta.rowIndex + 1}
              </div>
            )
          },
        },
      },
      {
        label: 'Category',
        name: 'category',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Status',
        name: 'risk_status',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Risk Level',
        name: 'risk_level',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Risk Description',
        name: 'risk_description',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Existing Measures',
        name: 'measure',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: '',
        name: '',
        filter: false,
        options: {
          sort: false,
          empty: true,
          filter: false,
          download: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRender: (value, tableMeta, updateValue) => {
            const indexVal =
              tableMeta?.currentTableData[tableMeta?.rowIndex]?.index
            const titleData = (
              <div>
                <p>{`Created By : ${
                  riskData[indexVal]?.created_by?.length > 0
                    ? adminData.employeeDetails.find(
                        (emp) =>
                          riskData[indexVal]?.created_by === emp.employee_id
                      )?.employee_name
                    : 'Admin'
                }`}</p>
                <p>
                  {riskData[indexVal]?.updated_by?.length > 0 &&
                    `Updated By : ${
                      adminData.employeeDetails.find(
                        (emp) =>
                          riskData[indexVal]?.updated_by === emp.employee_id
                      )?.employee_name
                    }`}
                </p>
              </div>
            )
            return (
              <div style={flexStyle}>
                <IconButton
                  aria-label="health action"
                  id="action-icon"
                  style={{ color: '#F58220' }}
                >
                  <LightTooltip title={'Edit Risk'}>
                    <EditIcon
                      onClick={(e) => handleEditRisk(e, riskData[indexVal])}
                    />
                  </LightTooltip>
                  {/* <SpeakerNotesIcon
                  onClick={(e) =>
                    handleActionOpen(updatedRows[dataIndex], dataIndex)
                  }
                /> */}
                </IconButton>
                <IconButton
                  aria-label="health action"
                  id="action-icon"
                  style={{ color: '#003153' }}
                >
                  <LightTooltip title={titleData}>
                    <InfoOutlinedIcon style={{ height: '18px' }} />
                  </LightTooltip>
                </IconButton>
              </div>
            )
          },
        },
      },
    ],
    [riskData]
  )

  let minWidth
  if (isxsScreen) {
    minWidth = '270px'
  } else if (isMediumScreen) {
    minWidth = '400px'
  } else if (isLargeScreen) {
    minWidth = '600px'
  }

  const handleEditRisk = (e, data) => {
    setCurrentRisk(data)
    setOpenEditRisk(true)
  }

  const handleEditRiskClose = (e) => {
    setCurrentRisk([])
    setOpenEditRisk(false)
    setAddRisk(false)
    //getRoleBasedData(page, year)
  }

  const handleViewRisk = async (e, data, dataIndex) => {
    await dispatch(getRiskByProject({ unique_id: data?.unique_id }))
    setCurrentRiskData({ data: data, index: dataIndex })
    setViewRisk(true)
  }

  const getMetaData = () => {
    let data
    if (userDetails.role === 'Reviewee') {
      const plemail = userDetails.email_id
      data = {
        plemail,
        role: userDetails.role,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Reviewer') {
      const adhemail = userDetails.email_id
      const verticalData = userDetails?.vertical_ids?.verticalInfo
      const verticalNames = verticalData?.length
        ? verticalData.map((vertical) => vertical.verticalName)
        : []
      data = {
        adhemail,
        role: userDetails.role,
        vertical: verticalNames,
        emp_id: userDetails.employee_id,
      }
    } else if (userDetails.role === 'Admin') {
      data = { role: userDetails.role, emp_id: userDetails.employee_id }
    }
    getAllMetaData(data)
      .then((res) => {
        if (res.success) {
          setMetadata(res.data.result)
        }
      })
      .catch((error) => {
        console.error('Error while fetching csat:', error)
      })
  }

  // const [year, setYear] = useState(currentYear)
  const roleBasedApiCall = async (data) => {
    setLoading(true)
    let week = `WE${getWeekDate(data?.week_no, data?.year)
      ?.replace(' ', '-')
      ?.replace(' ', '')}`
    let rowsData = await dispatch(
      fetchCurrentWeekMetaData({ ...data, week })
    ).unwrap()
    dispatch(updateWeeklyHealthInitialData(rowsData))
    setRows(rowsData)
    let values = JSON.parse(JSON.stringify(rowsData)).filter(
      (data) => data?.exclude == exclude
    )
    //let dataModified = filtersData(values)
    setUpdatedRows(values)
    //setUpdatedRows(rows)
    setLoading(false)
  }

  const getRoleBasedData = async (value, year) => {
    let weekNumber = getWeekNumber(year)
    const user_accounts = userDetails?.client_ids?.length
      ? userDetails.client_ids.map((client) => client.client_name)
      : []
    const verticalData = userDetails?.vertical_ids?.verticalInfo
    const user_verticals = verticalData?.length
      ? verticalData.map((vertical) => vertical.verticalName)
      : []
    const role = userDetails?.role || 'Reviewee'
    if (role == 'Reviewee') {
      await roleBasedApiCall({
        email_id: userDetails.email_id,
        role: 'Reviewee',
        account: user_accounts,
        week_no: value,
        verticals: [],
        currentWeek: weekNumber == value ? true : false,
        year: year,
      })
      dispatch(updateisApiCallMade(true))
      dispatch(updateCount(count + 1))
    } else if (role == 'Reviewer') {
      if (!userDetails?.vertical_ids) {
        await roleBasedApiCall({
          email_id: userDetails.email_id,
          role: 'Reviewer',
          account: user_accounts,
          week_no: value,
          verticals: [],
          currentWeek: weekNumber == value ? true : false,
          year: year,
        })
      } else {
        setfilter((prev) => ({
          ...prev,
          view: 'weekly-health',
          role: 'Reviewer',
          week_no: value,
          account: user_accounts,
          verticals: user_verticals,
          year: year,
        }))
      }
    } else if (role === 'Admin') {
      setfilter((prev) => ({
        ...prev,
        week_no: value,
        year: year,
        role: 'Admin',
        view: 'weekly-health',
      }))
    }
    //  else if (role === 'PMO') {
    //   setfilter((prev) => ({
    //     ...prev,
    //     view: 'weekly-health',
    //     email_id: userDetails.email_id,
    //     role: role,
    //     week_no: value,
    //     account: user_accounts,
    //     verticals: user_verticals,
    //     year: year,
    //   }))
    // }
    setLoading(false)
  }
  // close issues, corrective and preventive actions dialog
  const handleClose = () => {
    if (
      (conditions?.data?.health_status === 'RED' ||
        conditions?.data?.health_status === 'AMBER') &&
      (!conditions?.data?.issues ||
        !conditions?.data?.preventive_actions ||
        !conditions?.data?.corrective_actions)
    ) {
      const rowdata = JSON.parse(JSON.stringify(updatedRows))
      const modifiedRow = JSON.parse(JSON.stringify(conditions?.data))
      modifiedRow['editHealth'] = true
      modifiedRow['health_status'] = ''
      rowdata[conditions?.dataIndex] = modifiedRow
      setConditions({
        data: modifiedRow,
        dataIndex: conditions?.dataIndex,
        recalculate_health: true,
        issuesFlag: false,
      })
      setSelected((prev) => ({
        ...prev,
        [conditions?.data?.unique_id]: modifiedRow,
      }))
      setUpdatedRows(rowdata)
    }
    setOpenDialog(false)
  }

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleOpenInfo = async (data) => {
    let days
    const updatedInfo =
      data?.updatedBy === 'System' || !data?.updatedBy
        ? await getUpdatedBy({
            year: currentYear,
            unique_id: data?.unique_id,
          })
        : { data: [{ updated_on: '', updatedBy: '' }] }
    const updatedInfoValue = updatedInfo?.data?.[0]
    const updatedOnValue =
      data?.updatedBy === 'System' || !data?.updatedBy
        ? updatedInfoValue?.updated_on
        : data?.updated_on
    try {
      days = getTotalDaysByVaule(updatedOnValue)
    } catch (e) {
      console.log(e)
    }
    let updatedOn = '-'
    if (days >= 0) {
      updatedOn =
        days === 0
          ? `Today (${updatedOnValue})`
          : days === 1
          ? `Yesterday (${updatedOnValue})`
          : `${days} days ago (${updatedOnValue})`
    }
    const content = (
      <DialogContent sx={{ minWidth: '300px' }}>
        <Card sx={{ maxWidth: 400 }}>
          <CardContent>
            <Typography
              sx={{ fontSize: 14, marginBottom: '10px' }}
              color="text.secondary"
              gutterBottom
            >
              Project Information
            </Typography>
            <Typography
              variant="h5"
              component="div"
              sx={{ marginBottom: '10px' }}
            >
              {`Project : ${data?.project} (${data?.account})`}
            </Typography>
            {data?.workstream && (
              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: '10px' }}
              >
                {`Workstream : ${data?.workstream}`}
              </Typography>
            )}
            {data?.updatedBy === 'System' && (
              <Typography
                variant="h5"
                component="div"
                sx={{ marginBottom: '10px' }}
              >
                Copied By : System
              </Typography>
            )}
            <Typography
              variant="h5"
              component="div"
              sx={{ marginBottom: '10px' }}
            >
              {`Updated By : ${
                data?.updatedBy === 'System' || !data?.updatedBy
                  ? updatedInfoValue?.updatedBy
                      ?.replace(/(@tredence\.com|\d+|\.)/g, ' ')
                      ?.toUpperCase() || '-'
                  : data?.updatedBy
                      ?.replace(/(@tredence\.com|\d+|\.)/g, ' ')
                      ?.toUpperCase() || '-'
              }`}
            </Typography>
            <Typography variant="h5" component="div">
              {`Last Updated : ${updatedOn}`}
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
    )
    setOtherInfo(content)
    setOtherInfoOpen(true)
  }

  const handleCloseOtherInfo = () => {
    setOtherInfoOpen(false)
  }

  // MoM data dialog close
  const handleActionClose = () => {
    setOpenAction(false)
  }

  // MoM dialog data fetch
  async function handleActionOpen(data, index) {
    let currentMoM = ''
    let historyVal = ''
    const dataMoM = await dispatch(fetchMoMData(data)).unwrap()
    if (dataMoM?.length) {
      const historyPastYears = dataMoM?.filter((rec) => rec.year < currentYear)
      const historyThisYear = dataMoM?.filter(
        (rec) => rec.week_no !== weekNumber && rec.year == currentYear
      )
      historyVal = [...historyPastYears, ...historyThisYear]
      const curWeekHistory = dataMoM?.filter(
        (rec) => rec.week_no === weekNumber && rec.year == currentYear
      )
      if (curWeekHistory) {
        currentMoM = curWeekHistory[0]?.MoM
      }
    }
    const content = (
      <MoMDialog
        currentMoM={currentMoM}
        data={data}
        history={historyVal}
        page={page}
        weekNumber={weekNumber}
        userDetails={userDetails}
        handleActionClose={handleActionClose}
        minWidth={minWidth}
      />
    )
    setMoMContent(content)
    setOpenAction(true)
  }

  const filtersData = (data) => {
    let filteredData = data
    if (filterData.vertical && filterData.vertical.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          exclude == item?.exclude
      )
    }
    if (filterData.account && filterData.account.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          exclude == item?.exclude
      )
    }
    if (filterData.project && filterData.project.length > 0) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          filterData.project.includes(item.project) &&
          exclude == item?.exclude
      )
    }
    if (
      filterData.vertical.length === 0 &&
      filterData.health_status.length > 0
    ) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.health_status?.includes(item?.health_status) &&
          exclude == item.exclude
      )
    } else if (
      filterData.health_status &&
      filterData.health_status.length > 0
    ) {
      filteredData = filteredData.filter(
        (item) =>
          filterData.vertical.includes(item.vertical) &&
          filterData.account.includes(item.account) &&
          filterData.project.includes(item.project) &&
          filterData.health_status?.includes(item?.health_status) &&
          exclude == item.exclude
      )
    }
    return filteredData
  }

  const columns = useMemo(
    () => [
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          display: 'excluded',
          searchable: false,
          filterList:
            !editing && weeklyHealthInitialData.length > 0
              ? filterData?.vertical
              : [],
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          sort: false,
          filterList:
            !editing && weeklyHealthInitialData.length > 0
              ? filterData?.account
              : [],
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          sort: false,
          filterList:
            !editing && weeklyHealthInitialData.length > 0
              ? filterData?.project
              : [],
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Workstream',
        name: 'workstream',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px',
                  //flexWrap: 'wrap',
                }}
              >
                <LightTooltip title={'Click here to add new workstream'}>
                  <div>
                    <IconButton
                      aria-label="health action"
                      id="action-icon"
                      style={{ color: '#F58220', padding: 0 }}
                    >
                      <AddIcon
                        onClick={(e) =>
                          handleWorkstream(updatedRows[dataIndex], dataIndex)
                        }
                      />
                    </IconButton>
                  </div>
                </LightTooltip>
                <p>{updatedRows[dataIndex]?.workstream}</p>
              </div>
            )
          },
        },
      },
      {
        label: 'Exclude',
        name: 'exclude',
        options: {
          sort: false,
          // filterList: weeklyHealthInitialData.length > 0 ? filterData?.exclude : [],
          customHeadLabelRender: (columnMeta) => {
            return (
              <CustomHeader
                columnMeta={columnMeta}
                msg="Hover Below to See The Status"
              />
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const value = updatedRows[dataIndex]?.exclude || 'No'
            return (
              <div style={{ textAlign: 'center' }}>
                <LightTooltip
                  title={`Why Should we exclude This Project ? 
                            ${
                              updatedRows[dataIndex]?.reason_for_exclusion ||
                              'Not mentioned'
                            }`}
                >
                  <div>
                    {value}
                    <Switch
                      value={value}
                      checked={value == 'Yes' ? true : false}
                      onChange={(e) =>
                        handleModification(
                          e,
                          updatedRows[dataIndex],
                          dataIndex,
                          'exclude'
                        )
                      }
                    />
                  </div>
                </LightTooltip>
              </div>
            )
          },
        },
      },
      {
        label: 'Risk',
        name: 'risk_count',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
                <LightTooltip title={updatedRows[dataIndex]?.risk_count ? 'View Risk Details' :'Click here to Add Risks'}>                <div
                  style={{ textAlign: 'center', cursor: 'pointer', color:updatedRows[dataIndex]?.risk_count ? 'rgb(245, 120, 11)' : 'rgba(0, 0, 0, 0.87)', textDecoration:updatedRows[dataIndex]?.risk_count ? 'underline':'none' }}
                  onClick={(event) =>
                    handleViewRisk(event, updatedRows[dataIndex], dataIndex)
                  }
                >
                  {updatedRows[dataIndex]?.risk_count ?? '-'}
                </div>
              </LightTooltip>
            )
          },
        },
      },
      {
        label: 'Customer Escalation*',
        name: 'customer_escalations',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                className={updatedRows[dataIndex]?.customer_escalations
                  ?.toLowerCase()
                  ?.trim()}
                style={{ textAlign: 'center' }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={
                    updatedRows[dataIndex]?.customer_escalations
                      ?.toUpperCase()
                      ?.trim() || ''
                  }
                  sx={{ minWidth: '80px' }}
                  disabled={
                    (updatedRows[dataIndex]?.exclude || 'No') == 'No'
                      ? false
                      : true
                  }
                  onChange={(e) =>
                    handleModification(
                      e,
                      updatedRows[dataIndex],
                      dataIndex,
                      'customer_escalations'
                    )
                  }
                  size="small"
                >
                  {colors.map((color) => (
                    <MenuItem value={color}>{color}</MenuItem>
                  ))}
                </Select>
              </div>
            )
          },
        },
      },
      {
        label: 'Project Overrun*',
        name: 'project_overrun',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const projectOverrun =
              updatedRows[dataIndex]?.project_overrun?.toUpperCase()?.trim() ||
              ''
            return (
              <div
                className={projectOverrun?.toLowerCase()?.trim()}
                style={{
                  textAlign: 'center',
                  // display: 'flex',
                  // alignItems: 'center',
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={projectOverrun}
                  disabled={
                    (updatedRows[dataIndex]?.exclude || 'No') == 'No'
                      ? false
                      : true
                  }
                  sx={{ minWidth: '80px' }}
                  onChange={(e) =>
                    handleModification(
                      e,
                      updatedRows[dataIndex],
                      dataIndex,
                      'project_overrun'
                    )
                  }
                  size="small"
                >
                  {colors.map((color) => (
                    <MenuItem value={color}>{color}</MenuItem>
                  ))}
                </Select>
                {/* {(projectOverrun === 'AMBER' || projectOverrun === 'RED') && (
                  <LightTooltip
                    title={
                      updatedRows[dataIndex]?.financial_impact?.trim() || ''
                    }
                    placement="bottom"
                  >
                    <InfoOutlinedIcon
                      sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)' }}
                    />
                  </LightTooltip>
                )} */}
              </div>
            )
          },
        },
      },
      {
        label: 'CS Escalation*',
        name: 'cs_escalation',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                className={updatedRows[dataIndex]?.cs_escalation
                  ?.toLowerCase()
                  ?.trim()}
                style={{ textAlign: 'center' }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={
                    updatedRows[dataIndex]?.cs_escalation
                      ?.toUpperCase()
                      ?.trim() || ''
                  }
                  disabled={
                    (updatedRows[dataIndex]?.exclude || 'No') == 'No'
                      ? false
                      : true
                  }
                  sx={{ minWidth: '80px' }}
                  onChange={(e) =>
                    handleModification(
                      e,
                      updatedRows[dataIndex],
                      dataIndex,
                      'cs_escalation'
                    )
                  }
                  size="small"
                >
                  {colors.map((color) => (
                    <MenuItem value={color}>{color}</MenuItem>
                  ))}
                </Select>
              </div>
            )
          },
        },
      },
      {
        label: 'Delivery Escalation*',
        name: 'delivery_escalation',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                className={updatedRows[dataIndex]?.delivery_escalation
                  ?.toLowerCase()
                  ?.trim()}
                style={{ textAlign: 'center' }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  value={
                    updatedRows[dataIndex]?.delivery_escalation
                      ?.toUpperCase()
                      ?.trim() || ''
                  }
                  disabled={
                    (updatedRows[dataIndex]?.exclude || 'No') == 'No'
                      ? false
                      : true
                  }
                  sx={{ minWidth: '80px' }}
                  onChange={(e) =>
                    handleModification(
                      e,
                      updatedRows[dataIndex],
                      dataIndex,
                      'delivery_escalation'
                    )
                  }
                  size="small"
                >
                  {colors.map((color) => (
                    <MenuItem value={color}>{color}</MenuItem>
                  ))}
                </Select>
              </div>
            )
          },
        },
      },
      {
        label: 'Overall Health Status',
        name: 'health_status',
        options: {
          sort: false,
          filterList:
            !editing && weeklyHealthInitialData.length > 0
              ? filterData?.health_status
              : [],
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            const value =
              updatedRows[rowIndex]?.health_status?.trim()?.toUpperCase() ||
              'NONE'
            let styleValue = { background: 'none' }
            styleValue = getStyleValue(value)
            return {
              style: styleValue,
            }
          },
          customHeadLabelRender: (columnMeta) => {
            return (
              <CustomHeader
                columnMeta={columnMeta}
                msg="Click below to see the status"
              />
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return updatedRows?.[dataIndex]?.['editHealth'] == true &&
              updatedRows?.[dataIndex]?.['exclude'] !== 'Yes' &&
              statuses.filter(
                (status) => updatedRows?.[dataIndex]?.[status] == ''
              ).length == 0 ? (
              <div
                style={{
                  fontFamily: 'Poppins',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className={updatedRows[dataIndex]?.health_status?.toUpperCase()}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: '12px',
                    fontFamily: 'Poppins',
                    alignItems: 'center',
                  }}
                  onClick={(e) =>
                    calculateOverallHealth(updatedRows[dataIndex], dataIndex)
                  }
                >
                  Click Here
                </Button>
              </div>
            ) : (
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  updatedRows[dataIndex]?.exclude != 'Yes' &&
                  updatedRows[dataIndex]?.health_status
                    ?.trim()
                    ?.toUpperCase() !== 'PROVIDE INPUTS' &&
                  updatedRows[dataIndex]?.health_status
                    ?.trim()
                    ?.toUpperCase() !== 'GREEN' &&
                  handleShowStatus(updatedRows[dataIndex], dataIndex)
                }
              >
                <LightTooltip
                  title={
                    updatedRows[dataIndex]?.exclude != 'Yes' &&
                    updatedRows[dataIndex]?.health_status
                      ?.trim()
                      ?.toUpperCase() !== 'PROVIDE INPUTS' &&
                    updatedRows[dataIndex]?.health_status
                      ?.trim()
                      ?.toUpperCase() !== 'GREEN'
                      ? 'Click here'
                      : ''
                  }
                  placement="bottom"
                >
                  <div>
                    {updatedRows[dataIndex]?.health_status?.toUpperCase()}
                  </div>
                </LightTooltip>
              </div>
            )
          },
        },
      },
      {
        label: 'Info',
        name: 'actions',
        filter: false,
        options: {
          sort: false,
          empty: true,
          filter: false,
          download: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div style={flexStyle}>
                <IconButton
                  aria-label="health action"
                  id="action-icon"
                  style={{ color: '#F58220' }}
                >
                  <LightTooltip title={'View MoM'}>
                    <SpeakerNotesIcon
                      onClick={(e) =>
                        handleActionOpen(updatedRows[dataIndex], dataIndex)
                      }
                    />
                  </LightTooltip>
                </IconButton>
                <IconButton
                  aria-label="health action"
                  id="action-icon"
                  style={{ color: '#003153' }}
                >
                  <LightTooltip title={'View Project Info'}>
                    <InfoOutlinedIcon
                      style={{ height: '18px' }}
                      onClick={(e) => handleOpenInfo(updatedRows[dataIndex])}
                    />
                  </LightTooltip>
                </IconButton>
              </div>
            )
          },
        },
      },
      {
        label: 'id',
        name: 'id',
        options: {
          display: 'excluded',
          searchable: false,
          filter: false,
          download: false,
        },
      },
      {
        label: 'Salesforce Id',
        name: 'salesforce_opportunity_id',
        options: {
          sort: false,
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Week Number',
        name: 'week_no',
        options: {
          sort: false,
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
      {
        label: 'Week End Date',
        name: 'week',
        options: {
          sort: false,
          display: 'excluded',
          searchable: false,
          filter: false,
        },
      },
    ],
    [JSON.stringify(updatedRows), editing, JSON.stringify(filterData)]
  )

  const columns_nonEdit = useMemo(
    () => [
      {
        label: 'Vertical',
        name: 'vertical',
        options: {
          display: 'excluded',
          filterList:
            weeklyHealthInitialData.length > 0 ? filterData?.vertical : [],
          searchable: false,
        },
      },
      {
        label: 'Account',
        name: 'account',
        options: {
          sort: false,
          filterList:
            weeklyHealthInitialData.length > 0 ? filterData?.account : [],
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Project',
        name: 'project',
        options: {
          sort: false,
          filterList:
            weeklyHealthInitialData.length > 0 ? filterData?.project : [],
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Workstream',
        name: 'workstream',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <div style={{ fontWeight: 'bold' }}>{columnMeta.label}</div>
          },
        },
      },
      {
        label: 'Exclude',
        name: 'exclude',
        options: {
          sort: false,
          // filterList: weeklyHealthInitialData.length > 0 ? filterData.exclude : [],
          customHeadLabelRender: (columnMeta) => {
            return (
              <CustomHeader
                columnMeta={columnMeta}
                msg="Hover Below to See The Status"
              />
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div style={{ textAlign: 'center' }}>
                <LightTooltip
                  title={`Why Should we exclude This Project ? 
                            ${
                              updatedRows[dataIndex]?.reason_for_exclusion ||
                              'Not mentioned'
                            }`}
                >
                  <div> {updatedRows[dataIndex]?.exclude || 'No'}</div>
                </LightTooltip>
              </div>
            )
          },
        },
      },
      {
        label: 'Risk',
        name: 'risk_count',
        options: {
          sort: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
               <LightTooltip title={updatedRows[dataIndex]?.risk_count ? 'View Risk Details' :'Click here to Add Risks'}>                <div
                  style={{ textAlign: 'center', cursor: 'pointer', color:updatedRows[dataIndex]?.risk_count ? 'rgb(245, 120, 11)' : 'rgba(0, 0, 0, 0.87)', textDecoration:updatedRows[dataIndex]?.risk_count ? 'underline':'none' }}
                  onClick={(event) =>
                    handleViewRisk(event, updatedRows[dataIndex], dataIndex)
                  }
                >
                  {updatedRows[dataIndex]?.risk_count ?? '-'}
                </div>
              </LightTooltip>
            )
          },
        },
      },
      {
        label: 'Customer Escalation*',
        name: 'customer_escalations',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                className={updatedRows[dataIndex]?.customer_escalations
                  ?.toLowerCase()
                  ?.trim()}
                style={{ textAlign: 'center' }}
              >
                {updatedRows[dataIndex]?.customer_escalations}
              </div>
            )
          },
        },
      },
      {
        label: 'Project Overrun*',
        name: 'project_overrun',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const projectOverrun = updatedRows[dataIndex]?.project_overrun
              ?.toLowerCase()
              ?.trim()
            return (
              <div
                className={projectOverrun}
                style={{
                  textAlign: 'center',
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  // gap: '2px',
                }}
              >
                {updatedRows[dataIndex]?.project_overrun}
                {/* {projectOverrun === 'amber' && (
                  <LightTooltip
                    title={
                      updatedRows[dataIndex]?.financial_impact?.trim() || ''
                    }
                    placement="bottom"
                  >
                    <InfoOutlinedIcon
                      sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)' }}
                    />
                  </LightTooltip>
                )} */}
              </div>
            )
          },
        },
      },
      {
        label: 'CS Escalation*',
        name: 'cs_escalation',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                className={updatedRows[dataIndex]?.cs_escalation
                  ?.toLowerCase()
                  ?.trim()}
                style={{ textAlign: 'center' }}
              >
                {updatedRows[dataIndex]?.cs_escalation}
              </div>
            )
          },
        },
      },
      {
        label: 'Delivery Escalation*',
        name: 'delivery_escalation',
        options: {
          sort: false,
          filter: false,
          customHeadLabelRender: (columnMeta) => {
            return <CustomStatusHeader columnMeta={columnMeta} />
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                className={updatedRows[dataIndex]?.delivery_escalation
                  ?.toLowerCase()
                  ?.trim()}
                style={{ textAlign: 'center' }}
              >
                {updatedRows[dataIndex]?.delivery_escalation}
              </div>
            )
          },
        },
      },
      {
        label: 'Overall Health Status',
        name: 'health_status',
        options: {
          sort: false,
          filterList:
            weeklyHealthInitialData.length > 0 ? filterData.health_status : [],
          setCellProps: (cellValue, rowIndex, columnIndex) => {
            const value = updatedRows[rowIndex]?.health_status
              ?.trim()
              ?.toUpperCase()
            let styleValue = { background: 'none' }
            if (value === 'AMBER') {
              styleValue = {
                backgroundColor: '#ffdd76',
              }
            } else if (value === 'GREEN') {
              styleValue = { backgroundColor: '#33D27E42' }
            } else if (value === 'RED') {
              styleValue = { backgroundColor: '#f08080' }
            } else {
              styleValue = { background: 'none' }
            }
            return {
              style: styleValue,
            }
          },
          customHeadLabelRender: (columnMeta) => {
            return (
              <CustomHeader
                columnMeta={columnMeta}
                msg="Click below to see the status"
              />
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <div
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  updatedRows[dataIndex]?.health_status
                    ?.trim()
                    ?.toUpperCase() !== 'GREEN' &&
                  updatedRows[dataIndex]?.health_status
                    ?.trim()
                    ?.toUpperCase() !== 'PROVIDE INPUTS' &&
                  handleShowStatus(updatedRows[dataIndex], dataIndex)
                }
              >
                <LightTooltip
                  title={
                    updatedRows[dataIndex]?.health_status
                      ?.trim()
                      ?.toUpperCase() !== 'GREEN' &&
                    updatedRows[dataIndex]?.health_status
                      ?.trim()
                      ?.toUpperCase() !== 'PROVIDE INPUTS'
                      ? 'Click here'
                      : ''
                  }
                  placement="bottom"
                >
                  <div>
                    {updatedRows[dataIndex]?.health_status?.toUpperCase()}
                  </div>
                </LightTooltip>
              </div>
            )
          },
        },
      },
      {
        label: userDetails.role === 'Admin' ? 'Actions' : 'Info',
        name: 'actions',
        filter: false,
        options: {
          sort: false,
          empty: true,
          filter: false,
          download: false,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {columnMeta.label}
              </div>
            )
          },
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const active =
              updatedRows[dataIndex]?.health_status?.toUpperCase() !==
              'PROVIDE INPUTS'
            const styleVal = active
              ? { color: '#F58220' }
              : { color: '#F58220', opacity: 0.5 }
            return (
              <div style={flexStyle}>
                <IconButton
                  aria-label="health action"
                  id="action-icon"
                  style={styleVal}
                >
                  {userDetails?.role === 'Admin' ? (
                    <LightTooltip title={'View/Edit MoM'}>
                      <EditIcon
                        onClick={(e) =>
                          active &&
                          handleActionOpen(updatedRows[dataIndex], dataIndex)
                        }
                      />
                    </LightTooltip>
                  ) : (
                    <LightTooltip title={'View MoM'}>
                      <SpeakerNotesIcon
                        onClick={(e) =>
                          handleActionOpen(updatedRows[dataIndex], dataIndex)
                        }
                      />
                    </LightTooltip>
                  )}
                  {/* <SpeakerNotesIcon
                    onClick={(e) =>
                      handleActionOpen(updatedRows[dataIndex], dataIndex)
                    }
                  /> */}
                </IconButton>
                <IconButton
                  aria-label="health action"
                  id="action-icon"
                  style={{ color: '#003153' }}
                >
                  <LightTooltip title={'View Project Info'}>
                    <InfoOutlinedIcon
                      style={{ height: '18px' }}
                      onClick={(e) => handleOpenInfo(updatedRows[dataIndex])}
                    />
                  </LightTooltip>
                </IconButton>
              </div>
            )
          },
        },
      },
    ],
    [JSON.stringify(updatedRows), JSON.stringify(filterData)]
  )

  const onRemove = (index, removedFilter, filterList) => {
    setAnchorEl(null)
    if (index === 0) {
      const removedItems = rows
        .filter((item) => item.vertical === removedFilter)
        .map((item) => item.account)
      const removeProjects = rows.filter(
        (item) => item.vertical === removedFilter
      )
      const accounts = filterData.account.filter(
        (account) => !removedItems.includes(account)
      )
      const updatedAccounts = rows
        .filter((item) => accounts.includes(item.account))
        .map((item) => item.account)
      const projects = rows
        .filter((item) => updatedAccounts.includes(item.account))
        .map((item) => item)
      const updatedProjects = projects
        .filter((item) => filterData.project.includes(item.project))
        .map((item) => item.project)
      const excludeData = rows
        .filter((item) => updatedProjects.includes(item.project))
        .map((item) => item.exclude)
      const excludedUniq = _.uniq(excludeData)

      const statusData = rows
        .filter((item) => excludedUniq.includes(item.exclude || ''))
        .map((item) => item.health_status)
      const statusDataUniq = _.uniq(statusData)
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: _.uniq(updatedAccounts),
          project: _.uniq(updatedProjects),
          // exclude: filterData.exclude.length > 0 ? excludedUniq : filterList[4],
          health_status:
            filterData.health_status.length > 0
              ? statusDataUniq
              : filterList[10],
        })
      )
    } else if (index === 1) {
      const removedItems = rows
        .filter((item) => item.account === removedFilter)
        .map((item) => item.project)

      const projects = filterData.project.filter(
        (project) => !removedItems.includes(project)
      )
      const updatedProjects = rows
        .filter((item) => projects.includes(item.project))
        .map((item) => item.project)
      const excludeData = rows
        .filter((item) => updatedProjects.includes(item.project))
        .map((item) => item.exclude)
      const excludedUniq = _.uniq(excludeData)

      const statusData = rows
        .filter((item) => updatedProjects.includes(item.project))
        .map((item) => item.health_status)
      const statusDataUniq = _.uniq(statusData)
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: _.uniq(updatedProjects),
          //exclude: filterData.exclude.length > 0 ? excludedUniq : filterList[4],
          health_status:
            filterData.health_status.length > 0
              ? statusDataUniq
              : filterList[10],
        })
      )
    } else if (index === 2) {
      const removedItems = rows
        .filter((item) => item.project === removedFilter)
        .map((item) => item.project)

      const projects = filterData.project.filter(
        (project) => !removedItems.includes(project)
      )
      const updatedProjects = rows
        .filter((item) => projects.includes(item.project))
        .map((item) => item.project)
      const excludeData = rows
        .filter((item) => updatedProjects.includes(item.project))
        .map((item) => item.exclude)
      const excludedUniq = _.uniq(excludeData)

      const statusData = rows
        .filter((item) => updatedProjects.includes(item.project))
        .map((item) => item.health_status)
      const statusDataUniq = _.uniq(statusData)
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: filterList[2],
          //exclude: filterData.exclude.length > 0 ? excludedUniq : filterList[4],
          health_status:
            filterData.health_status.length > 0
              ? statusDataUniq
              : filterList[10],
        })
      )
    } else if (index === 4 && filterData.vertical.length > 0) {
      const removedItems = rows
        .filter((item) => item.exclude === removedFilter)
        .map((item) => item.exclude)

      const excludes = filterData.exclude.filter(
        (exclude) => !removedItems.includes(exclude)
      )
      const updatedExcludes = rows
        .filter((item) => excludes.includes(item.exclude))
        .map((item) => item.exclude)
      const excludedUniq = _.uniq(updatedExcludes)
      const statusData = rows
        .filter((item) => excludedUniq.includes(item.exclude || ''))
        .map((item) => item.health_status)
      const statusDataUniq = _.uniq(statusData)
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: filterList[2],
          //exclude: filterList[4],
          health_status:
            filterData.health_status.length > 0
              ? statusDataUniq
              : filterList[10],
        })
      )
    } else if (index === 4) {
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: filterList[2],
          //exclude: filterList[4],
          health_status: filterList[10],
        })
      )
    } else if (index === 10) {
      dispatch(
        updateFilterData({
          vertical: filterList[0],
          account: filterList[1],
          project: filterList[2],
          //exclude: filterList[4],
          health_status: filterList[10],
        })
      )
    }
  }

  const onExcludeChange = (event) => {
    setAnchorEl(null)
    let value = event.target.value == 'Yes' ? 'No' : 'Yes'
    dispatch(updateExcludeData(value))
    // if (value?.length>0){
    //   dispatch(updateFilterData({exclude:value }));
    // }else{
    //   dispatch(updateFilterData({ exclude:[] }));
    // }
  }

  const handleSearchClick = () => {
    setSearch((prev) => !prev)
  }

  const handleSearch = (value) => {
    setSearchText(value)
  }
  const handleClear = () => {
    setSearchText('')
  }
  const options = {
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    searchText: searchText,
    filter: false,
    print: false,
    viewColumns: false,
    rowsPerPageOptions: [5, 10, 25, 50],
    elevation: 0,
    fixedHeader: true,
    tableBodyHeight: '400px',
    download: false,
    downloadOptions: {
      filename: `weekly-health Week-${page}`,
    },
    // onDownload: (buildHead, buildBody, columns, data) => {
    //   const csvColumns = columns?.slice(0, columns?.length)
    //   return buildHead(csvColumns) + buildBody(data)
    // },
    textLabels: {
      body: {
        noMatch: 'No records found.',
      },
    },
    expandableRowsHeader: false,
    customToolbar: () => {
      return (
        <>
          {!editing && weeklyHealthInitialData.length > 0 && (
            <>
              <div className={styles.toolbarContainer}>
                <div className={styles.searchContainer}>
                  {search && (
                    <InputBase
                      placeholder="Search..."
                      value={searchText}
                      onChange={(e) => handleSearch(e.target.value)}
                      className={styles.searchInput}
                    />
                  )}
                  {searchText ? (
                    <IconButton type="button" onClick={handleClear}>
                      <ClearIcon onClick={handleClear} />
                    </IconButton>
                  ) : (
                    <SearchIcon
                      className={styles.searchIcon}
                      onClick={handleSearchClick}
                    />
                  )}{' '}
                  <Tooltip
                    title={'Click here to filter the data'}
                    leaveTouchDelay={3000}
                    enterTouchDelay={20}
                  >
                    <FilterListIcon
                      variant="contained"
                      color="primary"
                      onClick={handleClick}
                      sx={{
                        margin: '-8px',
                        marginLeft: '7px',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                  <Popper id={id} open={open} anchorEl={anchorEl}>
                    <Box
                      sx={{
                        p: 1,
                        backgroundColor: '#f9f9f9',
                        maxWidth:
                          isSmallScreen || isMediumScreen ? '300px' : '500px',
                      }}
                    >
                      <FilterComponent
                        setAnchorEl={setAnchorEl}
                        keyName={'weeklyHealth'}
                      />
                    </Box>
                  </Popper>
                </div>
                <div>
                  <h5 className={styles.excludesSwitch}>
                    Show Excludes :{' '}
                    <Switch
                      value={exclude}
                      checked={exclude == 'Yes' ? true : false}
                      onChange={(event) => onExcludeChange(event)}
                    />
                    {exclude}
                  </h5>
                </div>
              </div>
            </>
          )}
          <div className={styles.weekNumberAndfriday}>
            {/* <FilterSelect width={130} optionsListValues={filterData.week} label="Week" value={week_no} setValue={setWeek} /> */}
            <p>Week Number :</p>
            <div className={styles.weekNumberPagination}>
              {isSmallScreen ? (
                <Pagination
                  count={getWeekNumber(year)}
                  page={page}
                  onChange={handleChange}
                  renderItem={(item) => {
                    if (item.type === 'page' && item.page !== page) {
                      return null
                    }
                    return (
                      <PaginationItem
                        {...item}
                        shape="circular"
                        color="primary"
                        disabled={
                          (item.type === 'previous' && page === 1) ||
                          (item.type === 'next' && page === getWeekNumber(year))
                        }
                      />
                    )
                  }}
                />
              ) : (
                <Pagination
                  count={getWeekNumber(year)}
                  siblingCount={0}
                  boundaryCount={1}
                  page={page}
                  onChange={handleChange}
                  color="primary"
                />
              )}{' '}
              <div className={styles.smallscreenfridayWeek}>
                <b>WE</b>:{getFriday(page, year)}
              </div>{' '}
            </div>
            <div className={styles.fridayWeek}>
              <b>WE</b>:{getFriday(page, year)}
            </div>
          </div>
        </>
      )
    },
    onFilterChipClose: (index, removedFilter, filterList) => {
      if (!editing) {
        onRemove(index, removedFilter, filterList)
      } else {
        notify(`Please save and proceed`, {
          variant: 'info',
        })
      }
    },
    customFooter: (
      count,
      pageNo,
      rowsPerPage,
      changeRowsPerPage,
      changePage
    ) => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <Grid container spacing={2} columns={16}>
                <Grid
                  item
                  xs={16}
                  md={8}
                  lg={10}
                  order={{ xs: 2, md: 1, lg: 1 }}
                >
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    page={
                      pageNo > 0 && updatedRows?.length < rowsPerPage
                        ? 0
                        : pageNo
                    }
                    count={count}
                    rowsPerPage={rowsPerPage}
                    onPageChange={(_, pageNo) => changePage(pageNo)}
                    onRowsPerPageChange={(event) =>
                      changeRowsPerPage(event.target.value)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={16}
                  md={6}
                  lg={6}
                  order={{ xs: 1, md: 2, lg: 2 }}
                  className={styles.saveCancelButtons}
                >
                  <TableCell align="right">
                    {(userDetails.role === 'Reviewee' ||
                      (userDetails.role === 'Reviewer' &&
                        !userDetails?.vertical_ids)) && (
                      <div
                        style={{
                          display: 'flex',
                          fontSize: '12px',
                          alignItems: 'center',
                          gap: '10px',
                          justifyContent: 'flex-end',
                        }}
                      >
                       <Button
                          color="primary"
                          variant="contained"
                          sx={{ fontSize: '12px', fontFamily: 'Poppins' }}
                          onClick={handleFinalSave}
                          disabled={
                            saveInprogress ||
                            Object.keys(selected).length === 0 ||
                            Object.values(
                              JSON.parse(JSON.stringify(selected))
                            ).filter(
                              (data) =>
                                data.health_status === '' &&
                                data?.exclude !== 'Yes'
                            ).length !== 0
                          }
                        >
                          Save
                        </Button>
                        <Button
                          onClick={handleCancelAll}
                          variant="outlined"
                          sx={{ fontSize: '12px' }}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </TableCell>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableFooter>
      )
    },
  }

  // final save after updates
  const handleFinalSave = async () => {
    const selectedValues = Object.values(JSON.parse(JSON.stringify(selected)))
    if (
      selectedValues.filter(
        (data) => data.health_status == '' && data?.exclude !== 'Yes'
      )?.length != 0
    ) {
      notify(
        `Please fill all the required parameters and calculate overall health`,
        {
          variant: 'error',
        }
      )
    } else {
      if (
        selectedValues.filter(
          (data) =>
            (data?.health_status == 'RED' || data?.health_status == 'AMBER') &&
            data?.exclude !== 'Yes' &&
            (data?.issues == '' ||
              data.preventive_actions == '' ||
              data.corrective_actions == '')
        ).length != 0
      ) {
        notify(
          `Please fill the actions and issues for records where overall health status is RED or AMBER!`,
          {
            variant: 'error',
          }
        )
      } else {
        setSaveInprogress(true)
        const res = await dispatch(saveData(selected))
        if (res?.payload?.success) {
          setSelected({})
          //setRows(updatedRows)
          //setExistingWorkstream('')
          setEditing(false)
          //dispatch(updateExcludeData('No'))
          dispatch(
            updateFilterData({
              vertical: [],
              account: [],
              project: [],
              // exclude: ['No'],
              health_status: [],
            })
          )
          const verticalData = userDetails?.vertical_ids?.verticalInfo
          const verticalNames = verticalData?.length
            ? verticalData.map((vertical) => vertical.verticalName)
            : []
          const payLoadToAllWeeks = {
            year: new Date()?.getFullYear(),
            email_id: userDetails?.email_id,
            role: userDetails?.role,
            verticalNames: verticalNames,
          }
          dispatch(getAllWeeksProjectHealthData(payLoadToAllWeeks))
          setSaveInprogress(false)
          getRoleBasedData(page, year)
          notify(`Records Saved!`, {
            variant: 'success',
          })
          getMetaData()
          // dispatch(updateFilterData({ exclude: [excludeSwitch] }));
        } else {
          notify(`Something went wrong !`, {
            variant: 'error',
          })
          setSaveInprogress(false)
          //setExistingWorkstream('')
        }
      }
    }
  }

  // Discard all updates
  const handleCancelAll = () => {
    let values = JSON.parse(JSON.stringify(rows)).filter(
      (data) => data?.exclude == exclude
    )
    let data = filtersData(values)
    setUpdatedRows(data)
    // setUpdatedRows(rows)
    setEditing(false)
    setConditions({})
    //setExistingWorkstream('')
    setSelected({})
    // setChangedValues({})
  }

  const calculateOverallHealth = (data, dataIndex) => {
    if (statuses.filter((status) => data?.[status] == '')?.length != 0) {
      notify(
        `Please fill all the required parameters to calculate overall health!`,
        {
          variant: 'info',
        }
      )
    } else {
      const rowdata = JSON.parse(JSON.stringify(updatedRows))
      const modifiedRow = rowdata.filter(
        (d) => d.unique_id == data.unique_id
      )[0]
      const project_overrun = modifiedRow['project_overrun']?.toUpperCase()
      const delivery_escalation =
        modifiedRow['delivery_escalation']?.toUpperCase()
      const cs_escalation = modifiedRow['cs_escalation']?.toUpperCase()
      const customer_escalations =
        modifiedRow['customer_escalations']?.toUpperCase()
      const matchedItem = weeklyHealthInitialData?.find(
        (item) => item?.unique_id === modifiedRow?.unique_id
      )
      let unfilledItem
      if (
        (!modifiedRow?.newFlag && !matchedItem?.health_status) ||
        matchedItem?.health_status == 'Provide Inputs'
      ) {
        unfilledItem = allWeeksProjectHealthDta.filter(
          (item) => item?.unique_id === modifiedRow?.unique_id
        )[0]
      }
      if (unfilledItem) {
        modifiedRow['red_status_count'] =
          unfilledItem?.redStatusCount != null &&
          unfilledItem?.redStatusCount > 0
            ? unfilledItem?.redStatusCount
            : 0
        modifiedRow['amber_status_count'] =
          unfilledItem?.amberStatusCount != null &&
          unfilledItem?.amberStatusCount > 0
            ? unfilledItem?.amberStatusCount
            : 0
        modifiedRow['green_status_count'] =
          unfilledItem?.greenStatusCount != null &&
          unfilledItem?.greenStatusCount > 0
            ? unfilledItem?.greenStatusCount
            : 0
        modifiedRow['status_count'] =
          unfilledItem?.totalStatusCount != null &&
          unfilledItem?.totalStatusCount > 0
            ? unfilledItem?.totalStatusCount
            : 0
      }
      // setChangedValues(prev => ({ ...prev, issues: modifiedRow?.issues, preventive_actions: modifiedRow?.preventive_actions, corrective_actions: modifiedRow?.corrective_actions }))
      if (project_overrun === 'RED' || customer_escalations === 'RED') {
        modifiedRow['health_status'] = 'RED'
        modifiedRow['isEdited'] = 'RED'
        if (modifiedRow.newFlag) {
          modifiedRow.red_status_count = 1
          modifiedRow.amber_status_count = 0
          modifiedRow.green_status_count = 0
        } else {
          if (
            !matchedItem?.health_status ||
            (matchedItem?.health_status == 'Provide Inputs' && unfilledItem)
          ) {
            modifiedRow.red_status_count =
              (unfilledItem.redStatusCount || 0) + 1
          } else {
            modifiedRow.red_status_count =
              (modifiedRow.red_status_count || 0) + 1
            modifiedRow.amber_status_count = modifiedRow.amber_status_count || 0
            modifiedRow.green_status_count = modifiedRow.green_status_count || 0
          }
        }
      } else if (
        project_overrun === 'AMBER' ||
        delivery_escalation === 'RED' ||
        cs_escalation === 'RED' ||
        cs_escalation === 'AMBER' ||
        delivery_escalation === 'AMBER' ||
        customer_escalations === 'AMBER'
      ) {
        modifiedRow['health_status'] = 'AMBER'
        modifiedRow['isEdited'] = 'AMBER'
        if (modifiedRow?.newFlag) {
          modifiedRow.red_status_count = 0
          modifiedRow.amber_status_count = 1
          modifiedRow.green_status_count = 0
        } else {
          if (
            !matchedItem?.health_status ||
            (matchedItem?.health_status == 'Provide Inputs' && unfilledItem)
          ) {
            modifiedRow.amber_status_count =
              (unfilledItem.amberStatusCount || 0) + 1
          } else {
            modifiedRow.amber_status_count =
              (modifiedRow.amber_status_count || 0) + 1
            modifiedRow.red_status_count = modifiedRow.red_status_count || 0
            modifiedRow.green_status_count = modifiedRow.green_status_count || 0
          }
        }
      } else {
        modifiedRow['health_status'] = 'GREEN'
        modifiedRow['isEdited'] = 'GREEN'
        if (modifiedRow.newFlag) {
          modifiedRow.red_status_count = 0
          modifiedRow.amber_status_count = 0
          modifiedRow.green_status_count = 1
        } else {
          if (
            !matchedItem?.health_status ||
            (matchedItem?.health_status == 'Provide Inputs' && unfilledItem)
          ) {
            modifiedRow.green_status_count =
              (unfilledItem.greenStatusCount || 0) + 1
          } else {
            modifiedRow.green_status_count =
              (modifiedRow.green_status_count || 0) + 1
            modifiedRow.red_status_count = modifiedRow.red_status_count || 0
            modifiedRow.amber_status_count = modifiedRow.amber_status_count || 0
          }
        }
      }
      modifiedRow['editHealth'] = false
      //   delete modifiedRow['recalculate_health']
      if (modifiedRow.newFlag) {
        modifiedRow.status_count = 1
      } else {
        if (
          !matchedItem?.health_status ||
          (matchedItem?.health_status == 'Provide Inputs' && unfilledItem)
        ) {
          modifiedRow.status_count = unfilledItem.totalStatusCount + 1
        } else {
          if (matchedItem?.isEdited === 'RED') {
            modifiedRow.red_status_count = modifiedRow.red_status_count - 1
          } else if (matchedItem?.isEdited === 'AMBER') {
            modifiedRow.amber_status_count = modifiedRow.amber_status_count - 1
          } else if (matchedItem?.isEdited === 'GREEN') {
            modifiedRow.green_status_count = modifiedRow.green_status_count - 1
          }
          if (!matchedItem?.isEdited) {
            modifiedRow.status_count = 1
          }
        }
      }
      rowdata[dataIndex] = modifiedRow
      setConditions({
        data: modifiedRow,
        dataIndex,
        recalculate_health: false,
        issuesFlag: true,
      })
      setSelected((prev) => ({ ...prev, [data?.unique_id]: modifiedRow }))
      setUpdatedRows(rowdata)
    }
  }

  // handle change in exlude, cs_escalation, delivery_escalation, customer_escalations, project_overrun columns
  const handleModification = async (
    e,
    data,
    dataIndex,
    flag,
    changedValuesData
  ) => {
    const rowdata = JSON.parse(JSON.stringify(updatedRows))
    const currentDate = formatDate(new Date())
    const modifiedRow = rowdata.filter((d) => d.unique_id == data.unique_id)[0]
    if (flag == 'exclude') {
      modifiedRow[flag] = e.target.value == 'Yes' ? 'No' : 'Yes'
      if (modifiedRow[flag] == 'No') {
        modifiedRow['reason_for_exclusion'] = ''
        modifiedRow['health_status'] = ''
        //statuses.map(status =>modifiedRow[status]= modifiedRow?.[status] ?? '') -- to make status values '' instead of NULL to prevent click here button coming  on change from Yes to No
      }

      modifiedRow['updatedBy'] = userDetails?.email_id
      modifiedRow['updated_on'] = currentDate
      setSelected((prev) => ({ ...prev, [data?.unique_id]: modifiedRow }))
      setUpdatedRows(rowdata)
      setConditions({ data: modifiedRow, dataIndex })
      if (modifiedRow[flag] == 'Yes') {
        const cascadeData = await dispatch(
          getProjectList({ account: modifiedRow?.account })
        ).unwrap()
        const content = (
          <OthersBox
            updatedRows={updatedRows}
            setSelected={setSelected}
            setUpdatedRows={setUpdatedRows}
            data={modifiedRow}
            index={dataIndex}
            projects={[
              ...new Set(cascadeData.map((record) => record?.project)),
            ]}
            allProjectsData={cascadeData}
            userDetails={userDetails}
            setReasonOn={setReasonOn}
            week_no={weekNumber}
            year={year}
            week={`WE${getWeekDate(weekNumber, year)
              ?.replace(' ', '-')
              ?.replace(' ', '')}`}
            minWidth={minWidth}
            weeklyHealthInitialData={weeklyHealthInitialData}
            allWeeksProjectHealthDta={allWeeksProjectHealthDta}
          />
        )
        setReasonContent(content)
        setReasonOn(true)
      }
    } else if (flag == 'healthStatus') {
      modifiedRow['issues'] = changedValuesData?.issues || ''
      modifiedRow['preventive_actions'] =
        changedValuesData?.preventive_actions || ''
      modifiedRow['corrective_actions'] =
        changedValuesData?.corrective_actions || ''
      // const projectOverrun = modifiedRow?.project_overrun?.toUpperCase()
      // if (projectOverrun === 'RED' || projectOverrun === 'AMBER') {
      //   modifiedRow['financial_impact'] =
      //     changedValuesData?.financial_impact || ''
      // }
      modifiedRow['updatedBy'] = userDetails?.email_id
      modifiedRow['updated_on'] = currentDate
      setConditions({ data: modifiedRow, dataIndex, issuesFlag: false })
    } else if (
      [
        'project_overrun',
        'cs_escalation',
        'delivery_escalation',
        'customer_escalations',
      ].includes(flag)
    ) {
      modifiedRow[flag] = e.target.value
      // modifiedRow['recalculate_health'] = true
      modifiedRow['health_status'] = ''
      modifiedRow['updatedBy'] = userDetails?.email_id
      modifiedRow['updated_on'] = currentDate
      if (!modifiedRow['exclude']) {
        modifiedRow['exclude'] = 'No'
      }
      setConditions({ data: modifiedRow, dataIndex, recalculate_health: true })
    } else {
      modifiedRow[flag] = e.target.value
    }
    rowdata[dataIndex] = modifiedRow
    setSelected((prev) => ({ ...prev, [data?.unique_id]: modifiedRow }))
    setUpdatedRows(rowdata)
    if (!modifiedRow?.week_no) {
      modifiedRow.week_no = weekNumber
      modifiedRow.year = year
      modifiedRow.week = `WE${getWeekDate(weekNumber, year)
        .replace(' ', '-')
        .replace(' ', '')}`
      modifiedRow['updatedBy'] = userDetails?.email_id
      modifiedRow['updated_on'] = currentDate
    }
    rowdata[dataIndex] = modifiedRow
    setSelected((prev) => ({ ...prev, [data?.unique_id]: modifiedRow }))
    setUpdatedRows(rowdata)
    if (flag == 'healthStatus') {
      if (
        (conditions?.data?.health_status === 'RED' ||
          conditions?.data?.health_status === 'AMBER') &&
        (!changedValuesData.issues ||
          !changedValuesData.preventive_actions ||
          !changedValuesData.corrective_actions)
      ) {
        notify(
          `Please fill the issues, preventive actions and corrective actions.`,
          {
            variant: 'info',
          }
        )
      } else {
        setOpenDialog(false)
      }
    }
  }

  // create dynamic content for  issues, corrective and preventive actions dialog
  const handleShowStatus = (data, index) => {
    setOpenDialog(true)
    const content = (
      <IssuesDialog
        data={data}
        handleModification={handleModification}
        handleClose={handleClose}
        roleAccess={
          userDetails.role === 'Reviewee' ||
          (userDetails.role === 'Reviewer' && !userDetails?.vertical_ids)
        }
        index={index}
        minWidth={minWidth}
      />
    )
    setDialogContent(content)
  }

  // handle week number change
  const handleChange = async (event, value, yearData) => {
    if (Object.values(selected)?.length != 0) {
      notify(`Please save the changes!`, {
        variant: 'info',
      })
    } else {
      dispatch(updateWeekData(value))
      setUpdatedRows([])
      setSelected({})
      //setExistingWorkstream('')
      getRoleBasedData(value, yearData ?? year)
      //dispatch(updateExcludeData('No'))
    }
  }

  // workstream addition dialog open
  const handleWorkstream = (data, index) => {
    const content = (
      <WorkstreamDialog
        data={data}
        updatedRows={updatedRows}
        rows={rows}
        setSelected={setSelected}
        setUpdatedRows={setUpdatedRows}
        setWorkstreamOpen={setWorkstreamOpen}
        index={index}
        userDetails={userDetails}
        weekNumber={weekNumber}
        year={year}
        setEditing={setEditing}
        minWidth={minWidth}
      />
    )
    setWorkstreamContent(content)
    setWorkstreamOpen(true)
    setConditions({ data, index })
  }

  const handleYearChange = (e) => {
    let value = e.target.value
    if (!editing) {
      dispatch(updateYearData(value))
      handleChange('', 1, value)
    } else {
      notify(`Please save the changes!`, {
        variant: 'info',
      })
    }
  }

  async function getHealthRecords() {
    if (filterData.vertical.length > 0 || filterData.health_status.length > 0) {
      const filteredData = filtersData(rows)
      setRows(filteredData)
      let values = JSON.parse(JSON.stringify(filteredData)).filter(
        (data) => data?.exclude == exclude
      )
      let data = filtersData(values)
      setUpdatedRows(data)
      setLoading(false)
    } else {
      getRoleBasedData(page, year)
    }
  }

  async function getFilteredData(filter) {
    setLoading(true)
    let rowsdata = await dispatch(fetchFilteredData(filter)).unwrap()
    dispatch(updateWeeklyHealthInitialData(rowsdata))
    if (filterData.vertical.length > 0 || filterData.health_status.length > 0) {
      const filteredData = filtersData(rowsdata)
      setRows(filteredData)
      let values = JSON.parse(JSON.stringify(filteredData)).filter(
        (data) => data?.exclude == exclude
      )
      let data = filtersData(values)
      setUpdatedRows(data)
    } else {
      setRows(rowsdata)
      let values = JSON.parse(JSON.stringify(rowsdata)).filter(
        (data) => data?.exclude == exclude
      )
      let data = filtersData(values)
      setUpdatedRows(data)
    }
    setLoading(false)
  }

  const handleRiskClose = () => {
    const updatedRowdata = JSON.parse(JSON.stringify(updatedRows))
    const rowsdata = JSON.parse(JSON.stringify(rows))
    const initialData = JSON.parse(JSON.stringify(weeklyHealthInitialData))
    const modifiedRow = JSON.parse(JSON.stringify(currentRiskData?.data))
    const riskCount = riskData?.length > 0 ? riskData?.length : null
    modifiedRow['risk_count'] = riskCount
    const modifiedData = updatedRowdata.map((data) => {
      if (data?.unique_id === currentRiskData?.data?.unique_id) {
        return modifiedRow
      } else return data
    })
    const changedInitialData = initialData.map((data) => {
      if (data?.unique_id === currentRiskData?.data?.unique_id) {
        return { ...data, risk_count: riskCount }
      } else return data
    })
    const changedRowData = rowsdata.map((data) => {
      if (data?.unique_id === currentRiskData?.data?.unique_id) {
        return { ...data, risk_count: riskCount }
      } else return data
    })
    dispatch(updateWeeklyHealthInitialData(changedInitialData)) // select filter + add risk to update number when filter is reset without save
    setUpdatedRows(modifiedData)
    setRows(changedRowData) // - filter add vertical+account - add risk - edit health(diff row)-cancel -clear filters -see if updated risk count
    //---- issue came is that updated risk count goes away on cancel
    setRiskData([])
    setViewRisk(false)
    setCurrentRiskData([])
  }

  let mounted = true
  useEffect(() => {
    if (mounted === true && !isApiCalHappened) {
      getHealthRecords()
      dispatch(updateisApiCallMade(true))
    }
    return () => (mounted = false)
  }, [])

  useEffect(() => {
    getMetaData()
    dispatch(fetchRiskOptions()).unwrap()
  }, [])

  useEffect(() => {
    if (Object.keys(filter)?.length > 0) {
      getFilteredData(filter)
    }
  }, [JSON.stringify(filter), exclude])

  useEffect(() => {
    const filteredData = filtersData(weeklyHealthInitialData)
    setRows(filteredData)
    let values = JSON.parse(JSON.stringify(filteredData)).filter(
      (data) => data?.exclude == exclude
    )
    let data = filtersData(values)
    setUpdatedRows(data)
    //setUpdatedRows(filteredData)
  }, [JSON.stringify(filterData), exclude])

  useEffect(() => {
    if (Object.keys(selected)?.length != 0) {
      setEditing(true)
      if (
        conditions?.data?.exclude == 'No' ||
        conditions?.data?.exclude == ''
      ) {
        const rowdata = JSON.parse(JSON.stringify(updatedRows))
        const modifiedRow = JSON.parse(JSON.stringify(conditions?.data))
        if (
          statuses.filter((status) => conditions?.data?.[status] != '')
            .length != 0 &&
          !conditions?.data?.health_status
        ) {
          // rowdata.filter(d => d.unique_id == conditions?.data?.unique_id)[0]
          modifiedRow['editHealth'] = true
          modifiedRow['health_status'] = ''
          rowdata[conditions?.dataIndex] = modifiedRow
          setUpdatedRows(rowdata)
        }
        if (conditions?.recalculate_health) {
          //rowdata.filter(d => d.unique_id == conditions?.data?.unique_id)[0]
          modifiedRow['editHealth'] = true
          modifiedRow['health_status'] = ''
          rowdata[conditions?.dataIndex] = modifiedRow
          setUpdatedRows(rowdata)
        } else if (
          !conditions?.recalculate_health &&
          conditions?.issuesFlag == true &&
          (conditions?.data?.health_status === 'RED' ||
            conditions?.data?.health_status == 'AMBER')
        ) {
          handleShowStatus(conditions?.data, conditions?.dataIndex)
        }
      }
    }
  }, [JSON.stringify(selected), JSON.stringify(conditions)])

  // useEffect(() => {
  //   //setUpdatedRows(prev=>rows.filter(data=> data?.exclude==exclude))
  //   let values = JSON.parse(JSON.stringify(rows)).filter(
  //     (data) => data?.exclude == exclude
  //   )
  //   let data = filtersData(values)
  //   setUpdatedRows(data)
  // }, [exclude, JSON.stringify(rows)])

  useEffect(() => {
    if (riskDataForPrj?.length > 0) {
      const values = JSON.parse(JSON.stringify(riskDataForPrj)).filter(
        (data) => {
          if (
            data?.created_year < year ||
            (data?.created_year === year && data?.created_weekNo <= page)
          ) {
            return data
          }
        }
      )
      setRiskData(values)
    }
  }, [riskDataForPrj])

  useEffect(() => {
    if (isLoadingRiskForPrj || loading) {
      setOpenBackdrop(true)
    } else {
      setOpenBackdrop(false)
    }
  }, [isLoadingRiskForPrj,loading])

  return (
    <div className="weekly-health">
      <Loading open={openBackdrop} />
      <ThemeProvider theme={getMuiTheme()}>
        <div className={styles.yearDropdown}>
          <div className={styles.yearTitle}>
            <h4>Year : </h4>
          </div>
          <div className={styles.yearSelect}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="outlined"
              value={year}
              onChange={handleYearChange}
              sx={{ minWidth: '80px', height: '30px' }}
              size="small"
            >
              {years.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <MUIDataTable
          data={updatedRows}
          columns={
            getWeekNumber(year) == page &&
            (userDetails.role == 'Reviewee' ||
              (userDetails.role == 'Reviewer' && !userDetails?.vertical_ids)) &&
            year == currentYear
              ? columns
              : columns_nonEdit
          }
          options={options}
        />

        {/* issues,corrective actions,preventive actions dialog */}
        {openDialog && (
          <Dialog
            open={openDialog}
            fullWidth
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText
                id="alert-dialog-slide-description"
                sx={{ color: '#000000', fontSize: '14px' }}
              >
                {dialogContent}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}

        {/* MoM dialog */}
        <Dialog
          open={openAction}
          onClose={handleActionClose}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          {momContent}
        </Dialog>

        {/* reason for exclusion dialog */}
        <Dialog
          open={reasonOn}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          {reasonContent}
        </Dialog>

        {/* reason for workstream dialog */}
        <Dialog
          open={workstreamOpen}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          {workstreamContent}
        </Dialog>

        {/* updated info of the project*/}
        <Dialog
          open={otherInfoOpen}
          onClose={handleCloseOtherInfo}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          {otherInfo}
        </Dialog>

        {/* updated risk details of the project*/}
        <Dialog
          open={viewRisk}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
          PaperProps={{
            style: {
              maxHeight: '80vh',
              overflow: 'hidden',
            },
          }}
        >
          <DialogContent style={{ padding: '6px' }}>
            <div>
              {errorFetchRiskForPrj ? (
                <DialogContent sx={{ minWidth: '280px', textAlign: 'center' }}>
                  <h4
                    style={{
                      fontWeight: 500,
                      fontSize: '13px',
                      background:
                        'light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))',
                    }}
                  >
                    Unable to fetch risks
                  </h4>
                </DialogContent>
              ) : riskDataForPrj?.length === 0 ? (
                <DialogContent sx={{ minWidth: '280px', textAlign: 'center' }}>
                  <h4
                    style={{
                      fontWeight: 500,
                      fontSize: '13px',
                      background:
                        'light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))',
                    }}
                  >
                    No risks have been logged yet.
                  </h4>
                </DialogContent>
              ) : (
                <DialogContent>
                  {' '}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        padding: 0,
                        color: '#000000',
                        marginBottom: '10px',
                        fontSize: '12px',
                        display: 'flex',
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        gap: '3px',
                      }}
                    >
                      <span>
                        <b>Vertical</b> : {riskDataForPrj?.[0]?.vertical}
                      </span>
                      <span>
                        <b>Account</b> : {riskDataForPrj?.[0]?.account}
                      </span>
                      <span>
                        <b>Project</b> : {riskDataForPrj?.[0]?.project}
                      </span>
                      <span>
                        <b>Workstream</b> : {riskDataForPrj?.[0]?.workstream}
                      </span>
                    </div>
                  </div>
                  <RiskTable
                    data={riskData}
                    columns={riskColumns}
                    selectable={false}
                  />
                </DialogContent>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            {!errorFetchRiskForPrj &&
              getWeekNumber(year) === page &&
              !isLoadingRiskForPrj && (
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ fontSize: '12px' }}
                  onClick={() => {
                    setAddRisk(true)
                  }}
                >
                  Add Risk
                </Button>
              )}
            <Button
              color="primary"
              variant="contained"
              sx={{ fontSize: '12px' }}
              onClick={handleRiskClose}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {openEditRisk && (
          <RiskForm
            open={openEditRisk}
            onClose={handleEditRiskClose}
            metadata={metadata}
            verticals={[currentRisk?.vertical]}
            accounts={[currentRisk?.account]}
            workstreams={[currentRisk?.workstream]}
            projects={[currentRisk?.project]}
            employees={adminData.employeeDetails}
            editForm={true}
            editRow={currentRisk}
            user={userDetails}
            healthFlag={true}
          />
        )}
        {addRisk && (
          <RiskForm
            open={addRisk}
            onClose={handleEditRiskClose}
            metadata={metadata}
            verticals={[currentRiskData?.data?.vertical]}
            projects={[currentRiskData?.data?.project]}
            accounts={[currentRiskData?.data?.account]}
            unique_id={currentRiskData?.data?.unique_id}
            workstreams={
              currentRiskData?.data?.workstream
                ? [currentRiskData?.data?.workstream]
                : []
            }
            employees={adminData.employeeDetails}
            editForm={false}
            editRow={{}}
            user={userDetails}
            healthFlag={true}
          />
        )}
      </ThemeProvider>
    </div>
  )
}

export default WeeklyHealthView
