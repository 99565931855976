import { createTheme } from '@mui/material/styles'

const primary = '#F58220'
const secondary = '#F58220'
const common = '#ffa500'

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    common: {
      main: common,
    },
  },
  typography: {
    allVariants: {
      fontSize: '12px',
      textTransform: 'none',
      fontFamily: 'Poppins',
      padding: '5px',
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginTop: '30px',
          backgroundColor: 'transparent',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          height: '9px',
          width: '250px',
          fontsize: '12px',
          fontWeight: '500px',
          paddingRight: '70px',
          borderRadius: '5px 5px 0 0',
          borderBottom: '3px solid #ced3dc',
          marginRight: '12px',
          backgroundColor: '#edeff3',
          boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.1568627451)',
          '&.Mui-selected': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          borderLeft: `4px solid ${common}`,
          marginBottom: '8px',

          '&.Mui-expanded': {
            borderLeft: `4px solid ${common}`,
          },
        },
      },
    },
    MuiTableCell:{
      styleOverrides:{
        paddingCheckbox: {
          backgroundColor: 'inherit',
        },
      }
    },

    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the button.
        visibility: 'hidden',
      },
    },
    MuiToolbar:{
      styleOverrides:{
        root:{
          marginTop:'-10px'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(even)': {
            backgroundColor: '#FFF',
            '&.Mui-selected': {
              backgroundColor: '#FFF', // Selected row background
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#FFF', // Hovered selected row background
            },
            '&:hover': {
              backgroundColor: '#FFF', // Hover background color
            },
          },
          '&:nth-of-type(odd)': {
            backgroundColor: '#EEE',
            '&.Mui-selected': {
              backgroundColor: '#EEE', // Selected row background
            },
            '&.Mui-selected:hover': {
              backgroundColor: '#EEE', // Hovered selected row background
            },
            '&:hover': {
              backgroundColor: '#EEE', // Hover background color
            },
          },
          // hide last border
          '&:last-child td, &:last-child th': {
            border: 0,
          },
        },
      },
    },
  },
})
