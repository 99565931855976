import {
  CheckCircleOutlineOutlined,
  ChevronLeftRounded,
  ChevronRightRounded,
} from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { format } from 'date-fns'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../Common/Loading'
import { getRiskByProject } from '../../../redux/reducer/riskSlice'
import { updateFilterData } from '../../../redux/reducer/projectHealthFilterSlice'
import { saveRisk, updateRisk } from '../../../services/riskManagementService'
import ReadmeFile from './ReadmeFile'


const RiskForm = (props) => {
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const { enqueueSnackbar: notify } = useSnackbar()
  const [accountOptions, setAccountOptions] = useState([])
  const [projectOptions, setProjectOptions] = useState([])
  const [workstreamOptions, setWorkstreamOptions] = useState([])
  const [filteredprojects, setFilteredprojects] = useState([])
  const [activeStep, setActiveStep] = useState(
    props?.editForm || props?.healthFlag ? 1 : 0
  )
  const [errors, setErrors] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [openReadme, setOpenReadme] = useState(false)
  const {
    // isLoading: isRiskOptionssLoading,
    // error: riskOptionsError,
    data: riskOptionData,
  } = useSelector((state) => state.risk)
  const { userDetails } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const { data: adminData } = useSelector((state) => state.admin)
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState(
    props?.editForm
      ? props?.editRow
      : {
          vertical: '',
          vertical_id: null,
          account: '',
          account_id: null,
          project: '',
          salesforce_opportunity_id: null,
          workstream: '',
          workstream_id: null,
          unique_id: null,
          category: '',
          risk_description: '',
          consequence: '',
          measure: '',
          likelihood: '',
          impact: '',
          risk_level: '',
          risk_response: '',
          risk_response_details: '',
          risk_owner: '',
          risk_status: '',
          eta: '',
        }
  )
  const likelihoodAndImpactOptions = ['High', 'Medium', 'Low']
  const [inputValue, setInputValue] = useState('');

  // Lookup object for colors based on the priority label
  const colorMap = {
    Low: 'green',
    Medium: 'orange',
    High: 'red',
  }
  const riskLevelcolorMap = {
    Critical: 'red',  
    High: '#edb13c',  
    Medium: '#f2fb21',   
    Low: '#96bd49',        
    'Very Low': '#00A86B', 
  };

  const steps = [
    'Project Info',
    'Risk Identification',
    'Risk Analysis',
    'Risk Response',
  ]

  const riskLevelMatrix = [
    ['Critical', 'High', 'Medium'], // When A = Option 1
    ['High', 'Medium', 'Low'], // When A = Option 2
    ['Medium', 'Low', 'Very Low'], // When A = Option 3
  ]

  const iconStyle = {
    fontSize: 15,
    cursor: 'pointer',
    marginLeft: 'auto',
    fontWeight: 'bold',
  }

  const titleStyle = {
    fontSize: '15px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  }

  const validateStep = () => {
    const newErrors = {}
    if (activeStep === 0) {
      if (!formData?.vertical) newErrors.vertical = 'Vertical is required'
      if (!formData?.account) newErrors.account = 'Account is required'
      if (!formData?.project) newErrors.project = 'Project is required'
      if (!formData?.workstream && workstreamOptions?.length > 0)
        newErrors.worktream = 'Workstream is required'
    } else if (activeStep === 1) {
      if (!formData?.category) newErrors.category = 'Category is required'
      // if(errorMessage){
      //   setErrorMessage(''
      //   )
      //   setFormData((prevData) => ({
      //     ...prevData,
      //     category:'',
      //   }));
      // }
      if (!formData?.consequence)
        newErrors.consequence = 'Consequence is required'
      if (!formData?.risk_description)
        newErrors.risk_description = 'Risk Description is required'
    } else if (activeStep === 2) {
      // if (!formData?.measure) newErrors.measure = 'Risk Measure is required'
      if (!formData?.likelihood) newErrors.likelihood = 'Likelihood is required'
      if (!formData?.impact) newErrors.impact = 'Impact is required'
      if (!formData?.risk_level) newErrors.risk_level = 'Risk Level is required'
    } else if (activeStep === 3) {
      // if (!formData?.risk_response)
      //   newErrors.risk_response = 'Risk Response is required'
      // if (!formData?.risk_response_details)
      //   newErrors.risk_response_details = 'Risk Response Details is required'
      if (!formData?.risk_owner) newErrors.risk_owner = 'Risk Owner is required'
      if (!formData?.eta) newErrors.eta = 'Eta is required'
      if (!formData?.risk_status) newErrors.risk_status = 'Status is required'
    }
    setErrors(newErrors)
    return Object.keys(newErrors)?.length === 0
  }

  // Update form data for each input field
  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const handleVerticalChange = (event, newValue) => {
    const result = _.filter(props?.metadata, { vertical: newValue })
    setAccountOptions(_.uniq(_.map(result, 'account'))?.sort())
    setFormData({
      ...formData,
      vertical: newValue,
      vertical_id: result[0]?.vertical_id,
      account: '',
      account_id: null,
      project: '',
      salesforce_opportunity_id: null,
      workstream: '',
      workstream_id: null,
      unique_id: null,
    }) // Update formData with selected vertical
    setProjectOptions([])
    setWorkstreamOptions([])
  }
  const handleAccountChange = (event, val) => {
    if (val) {
      const result = _.filter(props?.metadata, { account: val })
      setProjectOptions(_.uniq(_.map(result, 'project'))?.sort())
      setFormData({
        ...formData,
        account: val,
        account_id: result[0]?.account_id,
        vertical: result[0]?.vertical,
        vertical_id: result[0]?.vertical_id,
        project: '',
        salesforce_opportunity_id: null,
        workstream: '',
        workstream_id: null,
        unique_id: null,
      }) // Update formData with selected account
      setWorkstreamOptions([])
    } else {
      setFormData({
        ...formData,
        account: val,
        project: '',
        workstream: '',
        workstream_id: null,
      })
      setProjectOptions([])
      setWorkstreamOptions([])
    }
  }
  const handleProjectChange = (event, val) => {
    if (val) {
      const result = _.filter(props?.metadata, { project: val })
      const uniqworkstreams = _.reject(result, { workstream: '' })
      const uniqNonnullworkstreams = _.reject(uniqworkstreams, {
        workstream: null,
      })
      setFilteredprojects(uniqNonnullworkstreams)
      setWorkstreamOptions(
        _.uniq(_.map(uniqNonnullworkstreams, 'workstream'))?.sort()
      )
      if (uniqNonnullworkstreams?.length == 0) {
        setFormData({
          ...formData,
          project: val,
          account_id: result[0]?.account_id,
          account: result[0]?.account,
          vertical: result[0]?.vertical,
          vertical_id: result[0]?.vertical_id,
          salesforce_opportunity_id: result[0]?.salesforce_opportunity_id,
          workstream: '',
          workstream_id: null,
          unique_id: result[0]?.unique_id,
        })
      } else {
        setFormData({
          ...formData,
          project: val,
          account_id: result[0]?.account_id,
          account: result[0]?.account,
          vertical: result[0]?.vertical,
          vertical_id: result[0]?.vertical_id,
          salesforce_opportunity_id: result[0]?.salesforce_opportunity_id,
          workstream: '',
          workstream_id: null,
          unique_id: null,
        })
      } // Update formData with selected account
    } else {
      setFormData({
        ...formData,
        project: '',
        workstream: '',
        workstream_id: null,
      })
      setWorkstreamOptions([])
    }
  }

  const handleWorkstreamChange = (event, val) => {
    if (filteredprojects?.length > 0) {
      const result = _.find(filteredprojects, { workstream: val })
      setFormData({
        ...formData,
        workstream: val,
        workstream_id: result?.workstream_id,
        unique_id: result?.unique_id,
      })
    }
  }

  const handleLikelihoodChange = (event, newValue) => {
    setFormData({
      ...formData,
      likelihood: newValue,
    })
  }
  const handleImpactChange = (event, newValue) => {
    setFormData({
      ...formData,
      impact: newValue,
    })
  }

  const handleInputChange = (event, newInputValue) => { 
    if (newInputValue.trim()) { 
      setInputValue(newInputValue);
      setFormData((prevData) => ({
        ...prevData,
        category: newInputValue,
      }));
      //setErrorMessage(''); 
    } else {
      setInputValue(''); 
      setFormData((prevData) => ({
        ...prevData,
        category: '', 
      }));
      //(''); 
    }
  };
  
  
  const handleategoryChange = (event, newValue) => {
    const validValue = newValue?.trim() 
    const userInput = inputValue.trim()
    if (validValue) {
      setFormData((prevData) => ({
        ...prevData,
        category: validValue,
      }));
    }
    else { setFormData((prevData) => ({
      ...prevData,
      category: '',
    }));
    //setErrorMessage('')
  }
  };

  const getIndex = (array, value) => array.indexOf(value)
  const determineRiskLevel = (likelihood, impact) => {
    // Logic to determine risk_level based on likelihood and impact
    const indexlikelihood = getIndex(likelihoodAndImpactOptions, likelihood)
    const indeximpact = getIndex(likelihoodAndImpactOptions, impact)

    // Ensure both indices are valid before accessing the result matrix
    if (indexlikelihood !== -1 && indeximpact !== -1) {
      return riskLevelMatrix[indexlikelihood][indeximpact]
    }
    return null
  }
  useEffect(() => {
    if (
      props?.user?.role == 'Reviewee' ||
      (props?.user?.role == 'Reviewer' && !props.user?.vertical_ids)
    ) {
      setAccountOptions(props?.accounts)
    } else if (props?.verticals?.length == 1) {
      const result = _.filter(props?.metadata, {
        vertical: props?.verticals[0],
      })
      setFormData({
        ...formData,
        vertical: props?.verticals[0],
        vertical_id: result[0]?.vertical_id,
      })
      if (props?.healthFlag && !props?.editForm) {
        const accResult = _.filter(props?.metadata, {
          account: props?.accounts[0],
        })
        setAccountOptions(_.uniq(_.map(accResult, 'account'))?.sort())
      } else {
        setAccountOptions(_.uniq(_.map(result, 'account'))?.sort())
      }
    }
  }, [props?.verticals, props?.user, props?.accounts])

  useEffect(() => {
    if (accountOptions?.length == 1) {
      const result = _.filter(props?.metadata, {
        account: accountOptions[0],
      })
      if (props?.healthFlag && !props?.editForm) {
        let prjResult = []
        if (props?.workstreams?.length > 0) {
          prjResult = _.filter(props?.metadata, {
            // vertical: result[0]?.vertical,
            // project: props?.projects?.[0],
            // workstream: props?.workstreams?.[0],
            unique_id:props?.unique_id
          })
          setFormData({
            ...formData,
            vertical: result[0]?.vertical,
            vertical_id: result[0]?.vertical_id,
            account: accountOptions[0],
            account_id: result[0]?.account_id,
            project: props?.projects?.[0],
            salesforce_opportunity_id:
              prjResult?.[0]?.salesforce_opportunity_id,
            workstream: prjResult?.[0]?.workstream,
            workstream_id: prjResult?.[0]?.workstream_id,
            unique_id: props?.unique_id,
          })
        } else {
          prjResult = _.filter(props?.metadata, {
            project: props?.projects?.[0],
          })
          setFormData({
            ...formData,
            vertical: result[0]?.vertical,
            vertical_id: result[0]?.vertical_id,
            account: accountOptions[0],
            account_id: result[0]?.account_id,
            project: props?.projects?.[0],
            salesforce_opportunity_id:
              prjResult?.[0]?.salesforce_opportunity_id,
            unique_id: prjResult?.[0]?.unique_id,
          })
        }
      } else {
        setProjectOptions(_.uniq(_.map(result, 'project'))?.sort())
        setFormData({
          ...formData,
          account: accountOptions[0],
          account_id: result[0]?.account_id,
        })
      }
    }
  }, [props?.metadata, accountOptions])

  useEffect(() => {
    if (props?.editForm) {
      const result = _.filter(props?.metadata, {
        account: formData?.account,
      })
      setProjectOptions(_.uniq(_.map(result, 'project'))?.sort())
    }
  }, [props?.editForm, props?.metadata, formData])

  useEffect(() => {
    const risklevel = determineRiskLevel(formData?.likelihood, formData?.impact)
    setFormData({
      ...formData,
      risk_level: risklevel,
    })
  }, [formData?.likelihood, formData?.impact])

  const handleRiskResponseChange = (event, newValue) => {
    setFormData({
      ...formData,
      risk_response: newValue,
    })
  }
  const handleRiskOwnerChange = (event, newValue) => {
    setFormData({
      ...formData,
      risk_owner: newValue?.email_id,
    })
  }
  const handleRiskStatusChange = (event, newValue) => {
    setFormData({
      ...formData,
      risk_status: newValue,
    })
  }

  // Go to the next step
  const handleNext = () => {
    if (validateStep()) {
      setActiveStep((prevStep) => prevStep + 1)
      setShowAlert(false)
    } else {
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false) // Hide alert after 3 seconds
      }, 2000) // Show alert if validation fails
    }
  }

  // Go to the previous step
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1)
  }

  // Handle form submission
  const handleSubmit = () => {
    if (validateStep()) {
      if (props?.editForm) {
        const { risk_id, ...updatedData } = formData
        const filledData = {
          riskId: props?.editRow?.risk_id,
          data: {
            ...updatedData,
            updated_by: props?.user?.employee_id,
            updatedAt: new Date(),
          },
        }
        setOpenBackdrop(true)
        updateRisk(filledData)
          .then((res) => {
            if (res.success) {
              notify(`Data Updated Successfully !`, {
                variant: 'success',
              })
              // call refresh table after edit in health or risk management
              props?.healthFlag
                ? dispatch(
                    getRiskByProject({ unique_id: props?.editRow?.unique_id })
                  )
                : Promise.all([props?.fetchRiskdata()])
                    .then(() => {
                      setOpenBackdrop(false)
                      dispatch(
                        updateFilterData({
                          vertical: [],
                          account: [],
                          project: [],
                          risk_status: [],
                          risk_level: [],
                        })
                      )
                    })
                    .catch((error) => {
                      console.error('Error fetching data:', error)
                      setOpenBackdrop(false)
                    })
            }
          })
          .catch((error) => {
            notify(`Couldn't Update Risk!`, {
              variant: 'error',
            })
            console.error('Error while updating risk:', error)
            setOpenBackdrop(false)
          })
      } else {
        setOpenBackdrop(true)
        const data = formData
        data.created_by = props?.user?.employee_id
        data.createdAt = new Date()
        saveRisk(data)
          .then((res) => {
            if (res.success) {
              notify(`Data Saved Successfully !`, {
                variant: 'success',
              })
              props?.healthFlag
                ? dispatch(getRiskByProject({ unique_id: formData?.unique_id }))
                : Promise.all([props?.fetchRiskdata()])
                    .then(() => {
                      setOpenBackdrop(false)
                      dispatch(
                        updateFilterData({
                          vertical: [],
                          account: [],
                          project: [],
                          risk_status: [],
                          risk_level: [],
                        })
                      )
                    })
                    .catch((error) => {
                      console.error('Error fetching data:', error)
                      setOpenBackdrop(false)
                    })
            }
          })
          .catch((error) => {
            notify(`Couldn't Save Risk!`, {
              variant: 'error',
            })
            console.error('Error while saving risk:', error)
            setOpenBackdrop(false)
          })
      }
      handleClose()
    } else {
      setShowAlert(true)
      setTimeout(() => {
        setShowAlert(false) // Hide alert after 3 seconds
      }, 2000)
    }
  }

  const handleClose = () => {
    props?.onClose()
    // setFormData()
    setActiveStep(0)
    setErrors({})
    setShowAlert(false)
  }
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.employee_name,
  })
  const renderOption = useCallback(
    (props, option) => (
      <Tooltip title={option?.email_id || ''} arrow>
        <li {...props}>{option?.employee_name}</li>
      </Tooltip>
    ),
    []
  )

  useEffect(() => {
    if (!formData?.risk_status) {
      setFormData((prevData) => ({
        ...prevData,
        risk_status: 'Open', // Set default to 'Open'
      }))
    }
  }, [formData])

  useEffect(()=>{
    if(!(props?.employees?.find(
      (option) => option?.email_id === formData?.risk_owner
    ))){
      setFormData((prevData) => ({
        ...prevData,
        risk_owner: null, 
      }))
    }
  },[formData?.risk_owner])

  useEffect(() => {
    if (formData?.risk_level === 'Low') {
      const responseOption = riskOptionData?.risk_response_options?.find(
        (option) => option === 'Accept'
      )
      if (responseOption) {
        setFormData((prevData) => ({
          ...prevData,
          risk_response: 'Accept' || '',
        }))
      }
    } else if (props?.editForm) {
      setFormData((prevData) => ({
        ...prevData,
        risk_response: formData?.risk_response || '',
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        risk_response: '',
      }))
    }
  }, [formData?.risk_level])

  // Render the form content based on the current step
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid align="center" container spacing={2}>
            {(props?.user?.role == 'Admin' ||
              (props?.user?.role == 'Reviewer' &&
                props.user?.vertical_ids)) && (
              <Grid item xs={6}>
                <Autocomplete
                  // disabled={props?.editRow?.account ? true : false}
                  options={props?.verticals}
                  value={
                    props?.verticals?.length == 1
                      ? props?.verticals[0]
                      : formData?.vertical
                  }
                  onChange={handleVerticalChange}
                  autoComplete="off"
                  disabled={props?.editForm || props?.healthFlag}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="vertical"
                      label="Vertical"
                      variant="outlined"
                      required
                      error={Boolean(!formData?.vertical)}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Tooltip
                title={
                  !formData?.vertical && accountOptions?.length == 0
                    ? 'Please Choose Vertical'
                    : ''
                }
              >
                <Autocomplete
                  // disabled={props?.editRow?.account ? true : false}
                  options={accountOptions}
                  value={
                    props?.accounts?.length == 1
                      ? props?.accounts[0]
                      : formData?.account
                  }
                  onChange={handleAccountChange}
                  autoComplete="off"
                  disabled={props?.editForm || props?.healthFlag}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Account"
                      variant="outlined"
                      required
                      error={Boolean(!formData?.account)}
                    />
                  )}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={
                  !formData?.account && projectOptions?.length == 0
                    ? 'Please Choose Account'
                    : ''
                }
              >
                <Autocomplete
                  options={projectOptions}
                  autoComplete="off"
                  value={formData?.project}
                  disabled={props?.editForm || props?.healthFlag}
                  onChange={handleProjectChange}
                  // onChange={handleChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project"
                      variant="outlined"
                      required
                      error={Boolean(!formData?.project)}
                    />
                  )}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip
                title={
                  // !props?.editRow?.workstream &&
                  workstreamOptions?.length == 0 && !props?.healthFlag
                    ? 'No workstreams'
                    : ''
                }
              >
                <Autocomplete
                  autoComplete="off"
                  disabled={workstreamOptions?.length > 0 ? false : true}
                  options={workstreamOptions}
                  value={formData?.workstream}
                  // defaultValue={props?.editRow?.workstream}
                  onChange={handleWorkstreamChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="medium"
                      required={workstreamOptions?.length > 0}
                      label="Workstream"
                      variant="outlined"
                    />
                  )}
                />
              </Tooltip>
            </Grid>
          </Grid>
        )
      case 1:
        return (
          <Grid align="center" container spacing={2}>
            <Grid item xs={6}>
              <Tooltip title='Choose category from the dropdown/add your inputs' placement="top" arrow>
                <Autocomplete
                  // disabled={props?.editRow?.account ? true : false}
                  options={[...riskOptionData.category_options].sort((a, b) =>
                    a.localeCompare(b) 
                  )}
                  freeSolo
                  value={formData?.category}
                  onChange={handleategoryChange}
                  onInputChange={handleInputChange}
                  autoComplete="off"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Risk Category"
                      variant="outlined"
                      required
                      error={Boolean(!formData?.category)}
                      />
                  )}
                />
                </Tooltip>
                {/* <Typography style={{ display: errorMessage ? 'block' : 'none',  }} variant="caption" color="error" align="left">
               {errorMessage}
               </Typography> */}
            </Grid>
            

            <Grid item xs={12}>
              <TextField
                name="risk_description"
                autoComplete="off" //   margin="normal"
                label="Risk Description"
                value={formData?.risk_description}
                required
                error={Boolean(!formData?.risk_description)}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="consequence"
                autoComplete="off" //   margin="normal"
                label="Consequence(s)"
                value={formData?.consequence}
                required
                error={Boolean(!formData?.consequence)}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        )
      case 2:
        return (
          <Grid align="center" container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="measure"
                autoComplete="off" //   margin="normal"
                label="Existing controls or measures"
                value={formData?.measure}
                // required
                error={Boolean(!formData?.measure)}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={likelihoodAndImpactOptions}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {/* Apply color based on the option */}
                    <span style={{ color: colorMap[option] }}>{option}</span>
                  </Box>
                )}
                value={formData?.likelihood ? formData?.likelihood : ''}
                onChange={handleLikelihoodChange}
                autoComplete="off"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Likelihood"
                    variant="outlined"
                    required
                    error={Boolean(!formData?.likelihood)}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={likelihoodAndImpactOptions}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {/* Apply color based on the option */}
                    <span style={{ color: colorMap[option] }}>{option}</span>
                  </Box>
                )}
                value={formData?.impact ? formData?.impact : ''}
                onChange={handleImpactChange}
                autoComplete="off"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Impact"
                    variant="outlined"
                    required
                    error={Boolean(!formData?.impact)}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Risk Level"
                disabled={formData?.risk_level ? false : true}
                value={''}
                InputProps={
                  formData?.risk_level
                    ? {
                        startAdornment: (
                          <Chip
                            label={formData?.risk_level}
                            sx={{
                              backgroundColor:
                                riskLevelcolorMap[formData?.risk_level],
                              color: 'black',
                            }}
                          />
                        ),
                        readOnly: true,
                      }
                    : null
                }
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        )
      case 3:
        return (
          <Grid align="center" container spacing={2}>
            <Grid item xs={6}>
              <Autocomplete
                options={riskOptionData?.risk_response_options}
                value={formData?.risk_response || ''}
                onChange={handleRiskResponseChange}
                autoComplete="off"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Risk Response"
                    variant="outlined"
                    // required
                    error={Boolean(!formData?.risk_response)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="risk_owner"
                autoComplete="off"
                options={props?.employees || []}
                getOptionLabel={(option) => option?.employee_name || ''} 
                filterOptions={filterOptions}
                value={
                  props?.employees?.find(
                    (option) => option?.email_id === formData?.risk_owner
                  ) || null
                }
                onChange={handleRiskOwnerChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={Boolean(!formData?.risk_owner)}
                    label="Risk Owner"
                    variant="outlined"
                  />
                )}
                renderOption={renderOption}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={riskOptionData?.risk_status_options}
                value={formData?.risk_status}
                onChange={handleRiskStatusChange}
                autoComplete="off"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    variant="outlined"
                    // required
                    error={Boolean(!formData?.risk_status)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="eta"
                variant="outlined"
                id="eta"
                label="ETA"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth={true}
                value={
                  formData?.eta
                    ? format(new Date(formData?.eta), 'yyyy-MM-dd')
                    : ''
                }
                onChange={handleChange}
                required
                error={Boolean(!formData?.eta)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="risk_response_details"
                autoComplete="off" //   margin="normal"
                label="Risk Response Details"
                value={formData?.risk_response_details}
                // required
                error={Boolean(!formData?.risk_response_details)}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        )
      default:
        return <div>Unknown step</div>
    }
  }

  const formatDate = (isoString) => {
    // Parse the ISO string into a Date object
    const date = new Date(isoString)

    // Format the date to "Sep 23, 2024" using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'short', // Sep
      day: 'numeric', // 23
      year: 'numeric', // 2024
    }).format(date)

    // Format the time to "9:34 PM"
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date)

    // Return the final formatted string
    return `${formattedDate}, ${formattedTime}`
  }

  const handleReadmeOpen = () => {
    setOpenReadme(true)
  }

  const handleReadmeClose = () => {
    setOpenReadme(false)
  }

  return (
    <>
      <Loading open={openBackdrop} />
      <Dialog maxWidth="md" open={props?.open} onClose={handleClose} fullWidth>
        <DialogTitle style={titleStyle}>
          <span style={{ marginRight: 'auto' }}>Risk Form</span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <span>
              <Typography
                variant="body1"
                color="primary"
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  paddingRight: '10px',
                }}
                onClick={handleReadmeOpen}
              >
                ReadMe File
              </Typography>
            </span>
            <CloseIcon onClick={handleClose} style={iconStyle} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ marginBottom: '1.6rem' }}
          >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {renderStepContent(activeStep)}

          {showAlert && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Please fill out all required fields.
            </Alert>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '15px',
            fontSize: '10px',
          }}
        >
          {/* Step navigation buttons */}
          <div style={{ flexGrow: 1, maxWidth: '65%' }}>
            {props?.editForm && (
              <div>
                <Typography>{`Created By : ${
                 props?.editRow?.created_by?.length > 0 ? props?.employees.find(
                    (emp) => props?.editRow?.created_by === emp.employee_id
                  )?.employee_name :'Admin'
                }, ${formatDate(props?.editRow?.createdAt)}`}</Typography>
                <Typography>
                  {props?.editRow?.updated_by?.length > 0 &&
                    `Updated By : ${
                      props?.employees.find(
                        (emp) => props?.editRow?.updated_by === emp.employee_id
                      )?.employee_name
                    }, ${formatDate(props?.editRow?.updatedAt)}`}
                </Typography>
              </div>
            )}
          </div>
          <div style={{ display: 'flex' }}>
            {activeStep !== 0 && (
              <Button
                onClick={handleBack}
                variant="contained"
                color="secondary"
                style={{
                  borderRadius: '50%',
                  minWidth: '2.2rem',
                  minHeight: '2.2rem',
                  width: '2.2rem',
                  height: '2.2rem',
                  padding: 0,
                  gap: 1,
                  position: 'relative',
                  // top: '1rem',
                  right: '2rem',
                }}
              >
                <ChevronLeftRounded />
              </Button>
            )}
            {activeStep === steps?.length - 1 ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                style={{
                  borderRadius: '50%',
                  minWidth: '2.2rem',
                  minHeight: '2.2rem',
                  width: '2.2rem',
                  height: '2.2rem',
                  padding: 0,
                  position: 'relative',
                  // top: '1rem',
                  right: '1rem',
                }}
              >
                <CheckCircleOutlineOutlined />
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                variant="contained"
                //disabled={errorMessage&&activeStep==1}
                color="primary"
                style={{
                  borderRadius: '50%',
                  minWidth: '2.2rem',
                  minHeight: '2.2rem',
                  width: '2.2rem',
                  height: '2.2rem',
                  padding: 0,
                  position: 'relative',
                  // top: '1rem',
                  right: '1rem',
                }}
              >
                <ChevronRightRounded />
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      {openReadme && (
        <ReadmeFile open={openReadme} onClose={handleReadmeClose} />
      )}
    </>
  )
}

export default RiskForm