import { Box, Popper, Tooltip, useMediaQuery } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@emotion/react'

const RiskTable = ({ data, columns, selectable, setSelectedRows }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const { riskInitialData, filterData } = useSelector((state) => state.filter)
  const [rows, setRows] = useState([])
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isBetweenSmAndMd = useMediaQuery(theme.breakpoints.between('sm', 'md'))

  useEffect(() => {
    setRows(data)
   // dispatch(updateRiskInitialData(data))
  }, [data])
  
  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    selectableRows: 'none',
    search: false,
    download: false,
    filter: false,
    print: false,
    viewColumns: false,
    selectToolbarPlacement: 'none',
    // customToolbar: () => {
    //   return (
    //     selectable && (
    //       <>
    //         <div
    //           style={{
    //             display: 'flex',
    //             alignItems: 'flex-end',
    //             justifyContent: 'flex-end',
    //           }}
    //         >
    //           {riskInitialData.length > 0 && (
    //             <>
    //               <Tooltip
    //                 title={'Click here to filter the data'}
    //                 leaveTouchDelay={3000}
    //                 enterTouchDelay={20}
    //               >
    //                 <FilterListIcon
    //                   variant="contained"
    //                   color="primary"
    //                   onClick={handleClick}
    //                   sx={{
    //                     marginTop: '-8px',
    //                     marginLeft: '7px',
    //                     cursor: 'pointer',
    //                   }}
    //                 />
    //               </Tooltip>
    //               <Popper
    //                 id={id}
    //                 open={open}
    //                 anchorEl={anchorEl}
    //                 placement="bottom-start"
    //               >
    //                 <Box
    //                   sx={{
    //                     p: 1.2,
    //                     backgroundColor: '#f9f9f9',
    //                     maxWidth:
    //                       isSmallScreen || isBetweenSmAndMd ? '300px' : '500px',
    //                   }}
    //                 >
    //                   <FilterComponent
    //                     setAnchorEl={setAnchorEl}
    //                     keyName={'riskManagement'}
    //                   />
    //                 </Box>
    //               </Popper>
    //             </>
    //           )}
    //         </div>
    //       </>
    //     )
    //   )
    // },
  }

  return <MUIDataTable data={rows} columns={columns} options={options} />
}

export default RiskTable
