import API from '../Common/api'

export const saveRisk = async (data) => {
  if (data) {
    try {
      const response = await API.post('/risk/saveRisk', data)

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while saving risk records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const getRiskOptions = async () => {
  try {
    const response = await API.get('/risk/getRiskOptions')

    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: { message: 'Something went wrong while fetching risk options!' },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getRiskdata = async (data) => {
  try {
    const response = await API.get(
      `/risk/getRiskdata?role=${data?.role}&plemail=${data?.plemail}&adhemail=${data?.adhemail}&verticals=${data.vertical}`
    )
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data.result,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message:
            'Something went wrong while getting project health MOM data!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}

export const getRiskByUniqueId = async (data) => {
  try {
    const response = await API.post('/risk/getRiskForPrj', data)
    if (response?.status === 200 && response?.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while getting project risk data!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: 'Something went wrong!',
    }
  }
}

export const updateRisk = async (data) => {
  if (data) {
    try {
      const response = await API.post(`/risk/updateRisk`, data)

      if (response.status === 200 && response.data) {
        return {
          success: true,
          data: response.data,
          error: null,
        }
      } else {
        return {
          success: false,
          data: null,
          error: {
            message: 'Something went wrong while updating risk records!',
          },
        }
      }
    } catch (error) {
      return {
        success: false,
        data: null,
        error: error,
      }
    }
  }
}

export const getAllMetaData = async (data) => {
  try {
    const response = await API.get(
      `/risk/getAllMetaData?role=${data?.role}&plemail=${data?.plemail}&adhemail=${data?.adhemail}&verticals=${data.vertical}&emp_id=${data.emp_id}`
    )
    if (response.status === 200 && response.data) {
      return {
        success: true,
        data: response.data,
        error: null,
      }
    } else {
      return {
        success: false,
        data: null,
        error: {
          message: 'Something went wrong while fetching csat records!',
        },
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      error: error,
    }
  }
}
