import { configureStore } from '@reduxjs/toolkit'
import AddcompilanceSlice from '../reducer/AddCompilance'
import adminSlice from '../reducer/adminSlice'
import checklistFilesSlice from '../reducer/checklistSharePointFiles'
import complianceSlice from '../reducer/complianceSlice'
import emailSlice from '../reducer/emailSlice'
import prHealthSlice from '../reducer/prHealthSlice'
import filterSlice from '../reducer/projectHealthFilterSlice'
import riskSlice from '../reducer/riskSlice'
import userSlice from '../reducer/userSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    admin: adminSlice,
    email: emailSlice,
    AddcompilanceSlice: AddcompilanceSlice,
    compliance: complianceSlice,
    allChecklistFiles: checklistFilesSlice,
    prHealth: prHealthSlice,
    filter: filterSlice,
    risk: riskSlice,
  },
})
