import { ThemeProvider } from '@mui/material/styles'
import { UserAgentApplication } from 'msal'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import ACKNOWLEDGE from './Common/AcknowledgeAlert'
import { setSessionDetails } from './Common/AppConstants'
import Loading from './Common/Loading'
import storageFactory from './Common/StorageFactory'
import API from './Common/api.js'
import ErrorModal from './Component/Error/ErrorModal'
import Header from './Component/Header/header.js'
import Login from './Component/Login/Login'
import config from './Config'
import { getUserData } from './GraphService'
import GuardedRoute from './ProtectedRoute.js'
import { theme } from './Styles/Theme.js'
import { fetchAdminDetails } from './redux/reducer/adminSlice'
import {
  fetchUserDetails,
  updateAccessToken,
  updatePowerbiAccessToken,
  updateDummyAccessToken,
} from './redux/reducer/userSlice'
import LoginNew from './Component/Login/LoginNew'
import Footer from './Common/Footer'
import {
  getAllWeeksProjectHealthData
} from '../src/redux/reducer/prHealthSlice'

const roleErrorDesc =
  'You are not an authorized user. Please reach out to "delivery-excellence@tredence.com" for the access.'
const roleErroTitle = 'Access Issue'

function App() {
  const dispatch = useDispatch()
  const { isLoading: isUserDetailsLoading, error: userDetailsError } =
    useSelector((state) => state.user)
  const emailId = useSelector((state) => state.user.userDetails.email_id)

  const { isLoading: isAdminDetailsLoading, error: adminDetailsError } =
    useSelector((state) => state.admin)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  // const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const today = new Date(),
    currentDate =
      today.getHours() +
      ':' +
      (today.getMinutes() <= '9'
        ? '0' + today.getMinutes()
        : today.getMinutes()) +
      ', ' +
      today.getDate() +
      ' ' +
      monthNames[today.getMonth()] +
      ', ' +
      today.getFullYear()

  const [isLoading, setIsLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [isUserRoleMissing, setIsUserRoleMissing] = React.useState(false)
  const [messageDetails, setMessageDetails] = useState({
    message: '',
    severity: '',
  })
  const [role, setRole] = useState(
    localStorage.getItem('project_review_employee_role') || 'Reviewer'
  )
  const [verticalIds, setVerticalIds] = useState('')

  const userAgentApplication = new UserAgentApplication({
    auth: {
      clientId: config.appId,
      authority:
        'https://login.microsoftonline.com/927e65b8-7ad7-48db-a3c6-c42a67c100d6',
      redirectUri: 'https://project-review.tredence.com/callback',
      // redirectUri: 'http://localhost:3000/auth/callback',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  })

  useEffect(() => {
    // API.post('signIn/getAccessToken').then((result) => {
    //   dispatch(updateDummyAccessToken(result.data.access_token))
    // })
    let user = userAgentApplication.getAccount()
    if (user) {
      // Enhance user object with data from Graph
      getUserProfile()
    }
  }, [])

  useEffect(() => {
    if (isUserDetailsLoading || isAdminDetailsLoading || isLoading) {
      setOpenBackdrop(true)
    } else {
      setOpenBackdrop(false)
    }

    if (userDetailsError) {
      setMessageDetails({
        message: userDetailsError,
        severity: 'error',
      })
    }

    if (adminDetailsError) {
      setMessageDetails({
        message: adminDetailsError,
        severity: 'error',
      })
    }

    return () => {
      setOpenBackdrop(false)
    }
  }, [
    isUserDetailsLoading,
    isAdminDetailsLoading,
    isLoading,
    userDetailsError,
    adminDetailsError,
  ])

  const changeRole = (rolechng) => {
    setRole(rolechng)
  }

  const postRequest = async () => {
    API.post('/signIn/logOut', {
      last_login_time: currentDate,
      emailId: emailId,
    })
      .then(() => console.log('request successful'))
      .catch(() => console.log('request failed'))
  }
  const handleLogin = async (emailId) => {
    if (process.env.NODE_ENV === 'development') {
      await getUserDetails(emailId)
    } else {
      try {
        await userAgentApplication.loginPopup({
          scopes: config.scopes,
          prompt: 'select_account',
        })
        await getUserProfile()
      } catch (err) {
        setIsAuthenticated(false)
        setMessageDetails({
          ...messageDetails,
          message: 'Error in Authentication',
          severity: 'error',
        })
      }
    }
  }

  const handleLogout = async () => {
    if (process.env.NODE_ENV === 'development') {
      await postRequest()
      localStorage.clear()
      window.location.replace(window.location.origin)
    } else {
      await postRequest()
      userAgentApplication.logout()
      await userAgentApplication.logout()
      localStorage.clear()
    }
  }

  const getUserProfile = async () => {
    setIsLoading(true)
    await userAgentApplication
      .acquireTokenSilent({
        scopes: config.scopes,
      })
      .then(async function (accessTokenResponse) {
        let accessToken = accessTokenResponse.accessToken
        if (accessToken) {
          dispatch(updateAccessToken(accessToken))
          let userData = await getUserData(accessToken)
          if (userAgentApplication.getAccount() !== null) {
            await getUserDetails(userData.mail)
          }
        }
      })
      .catch(function (error) {
        setIsAuthenticated(false)
        setIsLoading(false)
        setMessageDetails({
          ...messageDetails,
          message: 'Error in Authentication',
          severity: 'error',
        })
      })
  }

  const getUserDetails = async (emailId) => {
    try {
        const userDetails = await dispatch(fetchUserDetails(emailId)).unwrap();

        if (!userDetails.role || !userDetails.role.length) {
            setIsUserRoleMissing(true);
            return;
        }

        setRole(userDetails.role);
        setSessionDetails(userDetails);

        if (userDetails.vertical_ids?.verticalIds) {
            setVerticalIds(userDetails.vertical_ids.verticalIds);
        }

        const verticalData = userDetails.vertical_ids?.verticalInfo || [];
        const verticalNames = verticalData.map((vertical) => vertical.verticalName);

        const data = {
            year: new Date().getFullYear(),
            email_id: userDetails.email_id,
            role: userDetails.role,
            verticalNames: verticalNames,
        };
        await dispatch(getAllWeeksProjectHealthData(data)).unwrap();
        const inMemory = storageFactory();
        const adminDetails = await dispatch(fetchAdminDetails()).unwrap();
        inMemory.setAdminDetails({ data: adminDetails });

        setIsAuthenticated(true);
        setIsLoading(false);
    } catch (error) {
        console.error("Error fetching user or admin details:", error);
        setIsLoading(false);
    }
};


  const okAction = () => {
    setIsUserRoleMissing(false)
  }

  const cancelAction = () => {
    setIsUserRoleMissing(false)
  }

  const complianceroles = ['Admin', 'Reviewee' /*, 'PMO'*/]

  return (
    <ThemeProvider theme={theme}>
      <Loading open={openBackdrop} />
      {isUserRoleMissing && (
        <ErrorModal
          description={roleErrorDesc}
          title={roleErroTitle}
          okAction={okAction}
          cancelAction={cancelAction}
        ></ErrorModal>
      )}
      <ACKNOWLEDGE
        message={messageDetails.message}
        severity={messageDetails.severity}
      ></ACKNOWLEDGE>
      {isAuthenticated && !isUserRoleMissing ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Header changeRole={changeRole} handleLogout={handleLogout} />
          <div style={{ flex: 1 }}>
            <Routes>
              <Route
                path="/"
                element={
                  <GuardedRoute
                    isRouteAccessible={
                      role === 'Reviewer' ||
                      role === 'Reviewee' ||
                      role === 'Admin'
                      //|| role === 'PMO'
                    }
                    redirectRoute={'/'}
                    navigateRoute={'/project-health/weekly-health'}
                  />
                }
                exact
              />
              <Route
                path="/csat"
                element={
                  <GuardedRoute
                    isRouteAccessible={true}
                    redirectRoute={'/'}
                    element={'csat'}
                  />
                }
                exact
              />
              <Route
                path="/report"
                element={
                  <GuardedRoute
                    isRouteAccessible={true}
                    redirectRoute={'/'}
                    element={'report'}
                    navigateRoute={'/report'}
                  />
                }
                exact
              />
              <Route
                path="/risk"
                element={
                  <GuardedRoute
                    isRouteAccessible={true}
                    redirectRoute={'/'}
                    element={'risk'}
                  />
                }
                exact
              />

              <Route
                path="/adminEmpUpdate"
                element={
                  <GuardedRoute
                    isRouteAccessible={role === 'Admin'}
                    redirectRoute={'/'}
                    element={'adminEmpUpdate'}
                  />
                }
                exact
              />

              <Route
                path="/project-health"
                element={
                  <GuardedRoute
                    isRouteAccessible={true} // complianceroles.includes(role)}
                    redirectRoute={'/'}
                    navigateRoute={'/project-health/weekly-health'}
                    // element={'project-health'}
                  />
                }
              />

              <Route
                path="/project-health/:page"
                element={
                  <GuardedRoute
                    isRouteAccessible={true}
                    redirectRoute={'/'}
                    element={'project-health'}
                    navigateRoute={'/project-health/weekly-health'}
                  />
                }
                exact
              />
              {/* Catch-all route for unknown paths */}
              <Route path="*" element={<GuardedRoute />} exact />
            </Routes>
          </div>
          <Footer />
        </div>
      ) : (
        <LoginNew
          handleLogin={handleLogin}
          isLoading={isUserDetailsLoading || isAdminDetailsLoading || isLoading}
        />
        // <Login
        //   handleLogin={handleLogin}
        //   isLoading={isUserDetailsLoading || isAdminDetailsLoading || isLoading}
        // />
      )}
    </ThemeProvider>
  )
}

export default App
