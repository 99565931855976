import React from 'react'
import RiskManagement from './RiskManagement'

function Risks() {
  return (
    <>
      <RiskManagement />
    </>
  )
}

export default Risks
