import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { TabPanel, a11yProps } from '../../Common/TabPanel'

import { makeStyles } from '@mui/styles'
import Risks from './Risks'

function Risklogs() {
  const navigate = useNavigate()
  const { page } = useParams()
  const [value, setValue] = useState(0)

  useEffect(() => {
    switch (page) {
      default:
        setValue(0)
        break
    }
  }, [page])
  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
    switch (newValue) {
      default:
        navigate(`/risk`)
        break
    }
  }
  return (
    <>
      <Container maxWidth="xxl">
        <AppBar position="static" color="transparent" elevation={0}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
          >
            <Tab label="LOG RISKS" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={value}>
          <Risks />
        </TabPanel>
        <Outlet />
      </Container>
    </>
  )
}

export default Risklogs
